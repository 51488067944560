import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Notification from "../components/Notification";
import { clearCustomerRows } from "redux/actions/customerSearchAction";
import { mainAction, logUIErrors } from "redux/actions/interactionsActions";
import Markdown from "markdown-to-jsx";

const sectionError =
  "Error loading this section of the page. Please try refreshing.";

const EmptyState = (props) => {
  const [splashPageData, setSplashPageData] = useState("");
  const [comingSoonData, setComingSoonData] = useState("");
  const [archivingData, setArchivingData] = useState("");
  const [attachmentsData, setAttachmentsData] = useState("");
  const [helpData, setHelpData] = useState("");
  const [prospectMultiMatchCount, setProspectMultiMatchCount] = useState(0);
  const tabObject = useRef();
  const iconGearObject = useRef();

  const Link = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  const options = {
    overrides: {
      a: {
        component: Link
      }
    }
  };

  useEffect(() => {
    let _prospectMultiMatchCount = 0;
    if (props.interactionsReducer.interactions.length > 0) {
      props.interactionsReducer.interactions.map((interaction, index) => {
        _prospectMultiMatchCount +=
          interaction.customerName === interaction.phoneNumber
            ? Number("1")
            : Number("0");
        return _prospectMultiMatchCount;
      });
    }
    setProspectMultiMatchCount(_prospectMultiMatchCount);
  }, [props.interactionsReducer.interactions]);
  useLayoutEffect(() => {
    window.oneX.addElement(iconGearObject.current);
    window.oneX.addElement(tabObject.current);
    return () => {
      window.oneX.removeElement(iconGearObject);
      window.oneX.removeElement(tabObject);
    };
  }, []);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 5 * 60 * 60 * 1000); // Fetches UI static data again after every 5 hours in milliseconds

    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      const markdownContent = await fetch(
        process.env.REACT_APP_UI_STATIC_DATA_URL
      );
      const markdownSections = markdownContent.text();
      const staticContent = (await markdownSections).split("---");
      setSplashPageData(staticContent[0]); //First section of markdown file  - Splash Page/Get Started
      setComingSoonData(staticContent[1]); //Second section of markdown file - Coming Soon
      setArchivingData(staticContent[2]); //Third section of markdown file  - Archiving
      setAttachmentsData(staticContent[3]); //Fourth section of markdown file - Attachments
      setHelpData(staticContent[4]); //Fifth section of markdown file  - Help
    } catch (error) {
      setSplashPageData(
        `${sectionError} This does not impact sending or receiving messages`
      );
      setComingSoonData(sectionError);
      setArchivingData(sectionError);
      setAttachmentsData(sectionError);
      setHelpData(sectionError);
      let err;
      if (error.response) {
        // The request was made and the server responded with a status code
        err = error.response;
        err.stack = undefined;
        err.config = undefined;
      } else if (error.request) {
        // The request was made but no response was received
        err = error.request;
      } else {
        // Something happened in setting up the request that triggered an error
        err = error.message;
      }
      props.actions.logUIErrors(
        props.officeAssociateId,
        JSON.stringify({
          error: JSON.stringify(err),
          loggedInAssociateId: props.loggedInAssociateId
        }),
        "Error Loading UI Static Data",
        false
      );
    }
  };

  return (
    <>
      <section id="empty-state">
        <div className="-oneX-container">
          <div className="-oneX-row">
            <div
              ref={tabObject}
              data-type="-oneX-tab-module-container"
              className="-oneX-col-8"
            >
              <p className="-sfc-main-heading -oneX-heading--h4">
                Helpful Information
              </p>
              <div
                className="-oneX-tabs-container -oneX-tabs-mid-page"
                id="mid-page-tab"
              >
                <div className="-oneX-tabs-scroller">
                  <ul
                    role="tablist"
                    aria-orientation="horizontal"
                    aria-label="help_and_usage"
                    className="-oneX-tabs-list"
                  >
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="true"
                        tabIndex="0"
                        aria-controls="get-started-tab"
                        className="-oneX-tabs-button"
                      >
                        {" "}
                        Get Started
                      </button>
                    </li>
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="true"
                        tabIndex="0"
                        aria-controls="coming-soon-tab"
                        className="-oneX-tabs-button"
                      >
                        {" "}
                        Coming Soon
                      </button>
                    </li>
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="false"
                        tabIndex="-1"
                        aria-controls="archiving-tab"
                        className="-oneX-tabs-button"
                      >
                        Archiving
                      </button>
                    </li>
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="false"
                        tabIndex="-1"
                        aria-controls="attachments-tab"
                        className="-oneX-tabs-button"
                      >
                        Attachments
                      </button>
                    </li>
                    <li role="presentation" className="-oneX-tabs-item">
                      <button
                        role="tab"
                        aria-selected="false"
                        tabIndex="-1"
                        aria-controls="general-help-tab"
                        className="-oneX-tabs-button"
                      >
                        Help
                      </button>
                    </li>
                    <li role="presentation"></li>
                  </ul>
                </div>
                <div
                  className="-oneX-tabs-panel"
                  id="get-started-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="start-here"
                  style={{ padding: "5px" }}
                >
                  <ul>
                    <li>
                      Click on the<strong>New Message</strong>button to start a
                      new text or select an existing text on the left.
                    </li>
                    <li>
                      Set your<strong>Out of Office</strong>settings and
                      <strong>Manage Notifications</strong>by clicking on the
                      <div className="red-icon-background">
                        <div
                          ref={iconGearObject}
                          id="webui_setting"
                          className="-oneX-icon-container -oneX-icon--interactive gear-icon"
                        >
                          <div
                            className="-oneX-icon"
                            data-icon="webui_settings_fill"
                          >
                            {" "}
                          </div>
                        </div>
                      </div>
                      .
                    </li>
                  </ul>
                  {splashPageData.includes(sectionError) ? (
                    <div className="-oneX-heading--XS -oneX-typography-variant4 -oneX-heading--h5">
                      <br />
                      <br />
                      <Markdown>{splashPageData}</Markdown>
                      <br />
                      <br />
                    </div>
                  ) : (
                    <div>
                      <Markdown options={options}>{splashPageData}</Markdown>
                    </div>
                  )}
                  {(prospectMultiMatchCount >= 1 ||
                    props.interactionsReducer.officeMessagesUnreadCount >=
                      1) && (
                    <div>
                      <p className="-oneX-acquisition-heading -oneX-heading--XS -oneX-typography-variant4 -oneX-heading--h5">
                        Action Required
                      </p>
                      {prospectMultiMatchCount >= 1 && (
                        <Notification
                          type="informational"
                          className="notification"
                        >
                          There {prospectMultiMatchCount >= 2 ? "are" : "is"}{" "}
                          {prospectMultiMatchCount} message
                          {prospectMultiMatchCount >= 2 ? "s " : " "} that{" "}
                          {prospectMultiMatchCount === 1 ? "is" : "are"} not
                          associated with a customer in ECRM. Please note that
                          you do not need to associate or create a customer for
                          SPAM messages.
                        </Notification>
                      )}
                      {props.interactionsReducer.officeMessagesUnreadCount >=
                        1 && (
                        <div>
                          <Notification type="warning" className="notification">
                            There{" "}
                            {props.interactionsReducer
                              .officeMessagesUnreadCount >= 2
                              ? "are"
                              : "is"}{" "}
                            {
                              props.interactionsReducer
                                .officeMessagesUnreadCount
                            }{" "}
                            unread message
                            {props.interactionsReducer
                              .officeMessagesUnreadCount >= 2
                              ? "s"
                              : ""}
                            .
                          </Notification>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="-oneX-tabs-panel -oneX-hidden"
                  id="coming-soon-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="general"
                  style={{ padding: "15px" }}
                >
                  <br />
                  {comingSoonData.includes(sectionError) ? (
                    <div className="-oneX-heading--XS -oneX-typography-variant4 -oneX-heading--h5">
                      <Markdown>{comingSoonData}</Markdown>
                    </div>
                  ) : (
                    <div>
                      <Markdown options={options}>{comingSoonData}</Markdown>
                    </div>
                  )}
                </div>

                <div
                  className="-oneX-tabs-panel -oneX-hidden"
                  id="archiving-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="archiving"
                  style={{ padding: "10px" }}
                >
                  <br />
                  {archivingData.includes(sectionError) ? (
                    <div className="-oneX-heading--XS -oneX-typography-variant4 -oneX-heading--h5">
                      <Markdown>{archivingData}</Markdown>
                    </div>
                  ) : (
                    <div>
                      <Markdown options={options}>{archivingData}</Markdown>
                    </div>
                  )}
                  <br />
                </div>

                <div
                  className="-oneX-tabs-panel -oneX-hidden"
                  id="attachments-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="attachments"
                  style={{ padding: "15px" }}
                >
                  <br />
                  {attachmentsData.includes(sectionError) ? (
                    <div className="-oneX-heading--XS -oneX-typography-variant4 -oneX-heading--h5">
                      <Markdown>{attachmentsData}</Markdown>
                    </div>
                  ) : (
                    <div>
                      <Markdown options={options}>{attachmentsData}</Markdown>
                    </div>
                  )}
                  <br />
                </div>

                <div
                  className="-oneX-tabs-panel -oneX-hidden"
                  id="general-help-tab"
                  tabIndex="0"
                  role="tabpanel"
                  aria-labelledby="general"
                  style={{ padding: "15px" }}
                >
                  <br />
                  {helpData.includes(sectionError) ? (
                    <div className="-oneX-heading--XS -oneX-typography-variant4 -oneX-heading--h5">
                      <Markdown>{helpData}</Markdown>
                    </div>
                  ) : (
                    <div>
                      <Markdown options={options}>{helpData}</Markdown>
                    </div>
                  )}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    authReducer: state.authReducer,
    interactionsReducer: state.interactionsReducer,
    officeAssociateId: state.userInfoReducer.officeAssociateId,
    loggedInAssociateId: state.userInfoReducer.loggedInAssociateId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { mainAction, clearCustomerRows, logUIErrors },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyState);

import React, { Component } from "react";
import {
  mainAction,
  fetchInteractions,
} from "redux/actions/interactionsActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  CHANGE_FILTER_OPTIONS,
  SET_CURRENT_FILTER,
  RESET_INTERACTIONS,
} from "redux/actions/types";
import FilterOptions from "./FilterOptions";
import AssignmentFilter from "./AssignmentFilter";
import { CLEAR_CURRENT_INTERACTION } from "redux/actions/types";
import { sfc_routes } from "../../utils/general";
import { clearCustomerRows } from "../../redux/actions/customerSearchAction";

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchList: "",
      searchCriteriaEntered: false,
    };
  }

  componentDidUpdate() {
    if (
      this.props.interactionsReducer.currentFilter &&
      !this.props.interactionsReducer.isTextFilterOn
    ) {
      this.refs.searchInput.value = "";
    }
  }

  componentDidMount() {
    if (window.oneX) {
      window.oneX.addElement(this.refs.search);
      window.oneX.addElement(this.refs.newMessage);
      window.oneX.addElement(this.refs.myMessage);
    }

    setTimeout(function () {
      document
        .querySelectorAll("svg title")
        .forEach((element) => (element.outerHTML = ""));
      document
        .querySelectorAll("svg")
        .forEach((element) => element.removeAttribute("id"));
    }, 2000);
  }

  handleTextSearchKeyUp = (event) => {
    if (event.key === "Enter") {
      this.searchFilterHandler();
    }
  };

  handleTextSearchButton = () => {
    this.searchFilterHandler();
  };

  searchFilterHandler = () => {
    const textFilterString = this.refs.searchInput.value.trim();

    if (textFilterString.length > 0) {
      this.props.actions.mainAction(SET_CURRENT_FILTER, {
        isTextFilterOn: true,
        isButtonFilterOn: false,
        isNewFilterOn: false,
        isReadFilterOn: false,
        isRepliedToFilterOn: false,
        isFollowUpFilterOn: false,
        isHiddenFilterOn: false,
        textFilterLastSearch: textFilterString,
        value: this.props.interactionsReducer.currentFilter,
      });

      this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
        searchCriteria: "fuzzySearch",
        searchValue: textFilterString,
      });

      this.props.actions.fetchInteractions(
        this.props.userInfoReducer.officeAssociateId,
        this.props.userInfoReducer.currentOfficeMembers,
        undefined,
        {
          fuzzySearch: textFilterString,
          allMessages: true,
          hidden: false,
        }
      );
    } else {
      this.refs.searchInput.value =
        this.props.interactionsReducer.textFilterLastSearch || "";
    }
  };

  clearSearch = (event) => {
    this.refs.searchInput.value = "";
    this.props.actions.mainAction(RESET_INTERACTIONS, {});
    this.setState({
      searchCriteriaEntered: false,
    });
    this.props.actions.fetchInteractions(
      this.props.userInfoReducer.officeAssociateId,
      this.props.userInfoReducer.currentOfficeMembers,
      undefined,
      {
        hidden: false,
        assignments: this.props.interactionsReducer.selectedAssignmentsFilter,
        returnUnassigned: this.props.interactionsReducer.returnUnassigned,
        returnInactiveAssignments: this.props.interactionsReducer.returnInactiveAssignments,
      }
    );
  };

  render() {
    return (
      <div className="-oneX">
        <div className="options-container">
          {/* Start of search by Name/Number on In-tray */}
          <div className="-oneX-row -oneX-no-gutters">
            <div ref={"search"} className="-oneX-col-12">
              <fieldset className="-oneX-fieldset">
                <label
                  htmlFor="inpage-search"
                  className="-oneX-textfield__floatingLabel -oneX--floatUp">
                  Search by name, number, or wording in text messages
                </label>
                <input
                  ref={"searchInput"}
                  id="inpage-search"
                  className="-oneX-textfield--floating-input -oneX-textfield--control"
                  aria-required="true"
                  onKeyUp={this.handleTextSearchKeyUp}
                  aria-label="Search for customers"
                />
                {/* checks in search criteria is empty and displays search control */}
                {this.props.interactionsReducer.isTextFilterOn === false && (
                  <button
                    type="button"
                    id="inpage-clear-search"
                    className="-oneX-btn-control search-clear-search"
                    onClick={this.handleTextSearchButton}>
                    <div
                      aria-label="clear"
                      role="img"
                      className="-oneX-icon--controls--search"></div>
                  </button>
                )}
                {/* checks if search criteria has value and display clear control */}
                {this.props.interactionsReducer.isTextFilterOn === true && (
                  <button
                    type="button"
                    id="inpage-clear-button"
                    className="-oneX-btn-control search-clear-button"
                    onClick={(e) => this.clearSearch(e)}>
                    <div
                      aria-label="clear"
                      role="img"
                      className="-oneX-icon--controls--clear"></div>
                  </button>
                )}
              </fieldset>
            </div>
          </div>
          {/* End of search by Name/Number on In-tray */}

          {/* Start of Filter Radio buttons */}
          <FilterOptions />
          {/* End of Filter Radio buttons */}

          <div className="-oneX-row">
            <AssignmentFilter />

            <div
              style={{
                marginLeft: "auto",
                marginRight: "22px",
                marginTop: "12px",
              }}>
              <button
                className="-oneX-btn-primary button-size"
                title="New Message"
                id="sendNewText"
                onClick={() => {
                  this.props.history.push(sfc_routes.pages.search);
                  this.props.actions.clearCustomerRows();
                  this.props.actions.mainAction(
                    CLEAR_CURRENT_INTERACTION,
                    null
                  );
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    name: "MessageStatus",
                    value: " ",
                  });
                }}>
                New Message
              </button>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    interactionsReducer: state.interactionsReducer,
    filterOptions: state.interactionsReducer.filterOptions,
    userInfoReducer: state.userInfoReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction,
        fetchInteractions,
        clearCustomerRows,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Options);

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mainAction } from "../../redux/actions/interactionsActions";

class CorporateTemplateItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      inputContent: this.props.item.content,
      officePhone: this.props.displayedOfficePhone,
      panel: {
        visible: "",
        expansionHeight: { height: "41.6px" }
      }
    };
  }

  onClickHandler = (track) => {
    const _wordTrack = track;
    if (typeof _wordTrack === "string") {
      const _newWordTrack = _wordTrack
        .replace(/(&lt;)/g, "<")
        .replace(/(&gt;)/g, ">")
        .replace(/(&amp;)/g, "&")
        .replace(/<office phone number>/g, this.state.officePhone)
        .replace(/<office phone#>/g, this.state.officePhone);

      const { cursorPos } = this.props.textMessageBox;
      const _startMessage = this.props.messageBody
        ? this.props.messageBody.slice(0, cursorPos.start)
        : "";
      const _endMessage = this.props.messageBody
        ? this.props.messageBody.slice(cursorPos.end)
        : "";
      let updatedData = { ...this.props.item };
      if (updatedData.content) {
        updatedData.content = _newWordTrack;
      }
      this.props.actions.mainAction("SELECTED_TEMPLATE", {
        selectedTemplate: {
          type: "corporate",
          data: updatedData
        }
      });
      this.props.actions.mainAction(
        "UPDATE_CHAT",
        _startMessage + _newWordTrack + _endMessage
      );

      this.hideDrawer();
      // Give the document focus
      setTimeout(() => {
        const _textArea = document.getElementById("conTA");
        _textArea.focus();
      }, 1000);
    }
  };

  hideDrawer = () => {
    window.oneX.Drawer.hideDrawer();
  };

  render() {
    const { item, index } = this.props;

    return (
      <div className="-oneX-panel--expansion--nested">
        <div className="-oneX-panel-control">
          <b>{item.title}</b>
        </div>
        <div
          id={"template_" + item.title + "_" + index}
          tabIndex={index}
          className="-oneX-panel-content"
        >
          <div>
            {item.content}
            <div className="-oneX-row -oneX-no-gutters bottom-nav ">
              <div className="-oneX-col-10" />
              <a
                className="-oneX-link--block -oneX-link-tertiary"
                href="javascript:void(0);"
                onClick={(e) => this.onClickHandler(item.content)}
              >
                Select
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageBody: state.interactionsReducer.currentInteraction.messageBody,
    textMessageBox: state.interactionsReducer.textMessageBox,
    displayedOfficePhone:
      state.officeSettingsReducer.officePhone.displayedOfficePhone
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporateTemplateItem);

import React, { Component } from "react";


class LineOfBusinessDropDown extends Component {

  getLOBAndIntentValue(e) {
    this.props.dropdownValues(e.target.name, e.target.value);
  };
  
  render() {
    const { interactionId } = this.props
    return (
      <>
  <br/>
  <br/>
  <div className = "-oneX-row">
  <div className ="-oneX-col-sm-6">
    <label htmlFor={"lineOfBusiness-"+interactionId} className="-oneX-dropdown__conversationalLabel">Line of Business</label>
    <select id={"lineOfBusiness-"+interactionId} name="lineOfBusiness" type="text" className="-oneX-dropdown" defaultValue="Unknown" 
    onChange={(e) => this.getLOBAndIntentValue(e)} onClick={(e) => e.stopPropagation()}
    >
      <option value="Unknown">Unknown</option>
      <option value="Auto">Auto</option>
      <option value="Bank">Bank</option>
      <option value="Billing/Payments">Billing/Payments</option>
      <option value="Fire">Fire</option>      
      <option value="IFR">Simple Conversation</option>
      <option value="Life">Life</option>
      <option value="Multi-Line">Multi-Line</option>
      <option value="Mutual Funds">Mutual Funds</option>
      <option value="Other">Other</option>
      <option value="Spam">Spam</option>
    </select>
  </div>
  <div className=" -oneX-col-sm-6">
    <label htmlFor={"intent-"+interactionId} className="-oneX-dropdown__conversationalLabel">Intent </label>
    <select id={"intent-"+interactionId} type="text" name="intent" className="-oneX-dropdown" defaultValue="Unknown" 
    onChange={(e) => this.getLOBAndIntentValue(e)} onClick={(e) => e.stopPropagation()}
    >
      <option value="Unknown">Unknown</option>
      <option value="Application">Application</option>
      <option value="Appointment">Auto</option>
      <option value="Billing/Payment">Billing/Payment</option>
      <option value="Callback">Callback</option>
      <option value="Cancellation">Cancellation</option>
      <option value="Change">Change</option>
      <option value="Claim">Claim</option>
      <option value="Discount">Discount</option>
      <option value="e-Signature">e-Signature</option>
      <option value="Lien/Lessor/Mortgage">Lien/Lessor/Mortgage</option>
      <option value="Marketing">Marketing</option>
      <option value="Master Record">Master Record</option>
      <option value="Message">Message</option>
      <option value="Other">Other</option>
      <option value="Proof of Insurance">Proof of Insurance</option>
      <option value="Quote">Quote</option>
      <option value="Refund">Refund</option>
      <option value="Sales">Sales</option>
      <option value="Service">Service</option>
      <option value="Spam">Spam</option>
      <option value="Underwriting">Underwriting</option>
      <option value="X-Date">X-Date</option>
    </select>
  </div>
  
</div>
      </>
    )
  }
}

export default React.memo(LineOfBusinessDropDown);
import React from "react";
import { useSelector } from "react-redux";
import OfficeSettingsPanelHeader from "./OfficeSettingPanelHeader";
import OutOfOfficeCheckbox from "./OutOfOfficeCheckbox";
import EmailNotificationCheckbox from "./EmailNotificationCheckbox";
import OfficeAdministratorsSettings from "./OfficeAdministratorsSettings";
import AddRemoveOffice from "./AddRemoveOffice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export default function SideDrawer() {
  const [expanded, setExpanded] = React.useState(false);
  const loggedInAssociateId = useSelector(
    (state) => state.userInfoReducer.loggedInAssociateId
  );
  const isAgent = useSelector(
    (state) => state.userInfoReducer.isAgent
  );  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <section
        id="myDrawer"
        className="-oneX -oneX-drawer-container"
        style={{ display: "none" }}>
        <OfficeSettingsPanelHeader />
        <div className="-oneX-body--primary -oneX-drawer-body">
          <div>
            Choose the topic to select options. <br />
            For MOAs, login under each alias to adjust settings.
          </div>
          <hr className="-oneX-panel-divider" />
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">
                <strong>Manage Notifications</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EmailNotificationCheckbox />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">
                <strong>Out of Office Message Service</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OutOfOfficeCheckbox />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">
                <strong>Manage Office Administrators</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OfficeAdministratorsSettings />
            </AccordionDetails>
          </Accordion>
          { 
            isAgent &&
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">
                  <strong>Add/Remove an Additional Office</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AddRemoveOffice />
              </AccordionDetails>
            </Accordion>
          }
        </div>
        <button
          id="closeButtonX"
          className="-oneX-close-X-button"
          aria-label="Close Drawer">
          <span className="-oneX-icon--close"></span>
        </button>
      </section>
    </>
  );
}

import { validateArray } from "./arrayValidator";

//Checking if first and last name are present
let createName = (firstName, lastName, phoneNumber) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (lastName) {
    return `${lastName}`;
  } else {
    return phoneNumber;
  }
};

//Converting timestamp to human readable date
export const convertEpochToDate = (date) => {
  let updatedDate = new Date(date);
  let today = new Date();
  if (today.toDateString() === updatedDate.toDateString()) {
    return getTimeString(date);
  } else {
    return getDateString(date);
  }
};

export const getTimeString = (date) => {
  let updatedDate = new Date(date);
  return updatedDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getDateString = (date) => {
  let updatedDate = new Date(date);
  return `${
    updatedDate.getMonth() + 1
  }/${updatedDate.getDate()}/${updatedDate.getUTCFullYear()}`;
};

export const getDateTime = {
  today: new Date(),
  formatedDate: () => {
    var dd = getDateTime.today.getDate();
    var mm = getDateTime.today.getMonth() + 1;
    var yyyy = getDateTime.today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return `${mm}/${dd}/${yyyy}`;
  },
  formatedTime: () => {
    var hr = getDateTime.today.getHours();
    var min = getDateTime.today.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    return `${hr}:${min} ${ampm}`;
  },
};

//Create initials for chat card
let createInitials = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  } else if (lastName) {
    return `${lastName[0]}`;
  } else {
    return "?";
  }
};

//Phone number mask
export function createPhoneNumberMask(number) {
  if (number !== null && number !== undefined) {
    number = number.toString();
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
  }
};

let createDisplayAgentCode = (agentCode) => {
  if (agentCode !== null && agentCode.length > 0) {
    //return `${agentCode.slice(0, 2)} - ${agentCode.slice(2)}`;
    return `${agentCode.slice(2)}`;
  }
};

export function translateMultiMatchInfo(multiMatchClientIDs, clientInfoList) {
  let multiMatchIDNameMap = [];
  multiMatchClientIDs.forEach((multiMatchClientID) => {
    let multiMatchClientDetails = clientInfoList.filter(
      (clientInfo) =>
        clientInfo &&
        (clientInfo.clientId === multiMatchClientID ||
          clientInfo.clientID === multiMatchClientID)
    );

    let multiMatchClientName = "";
    //Known issue: If client could not be pulled, multiMatchClientName remains blank
    if (validateArray(multiMatchClientDetails)) {
      const client = multiMatchClientDetails[0];
      //null check for first name is for company names which have no last name
      const _first =
        client.firstName && client.firstName.trim().length !== 0
          ? client.firstName + " "
          : "";
      const _middle =
        client.middleName && client.middleName.trim().length !== 0
          ? client.middleName + " "
          : "";
      const _last =
        client.lastName && client.lastName.trim().length !== 0
          ? client.lastName
          : "";
      multiMatchClientName = _first + _middle + _last;
    }
    multiMatchIDNameMap.push({
      clientID: multiMatchClientID,
      clientName: multiMatchClientName,
    });
  });
  return multiMatchIDNameMap;
}

export function getLastMessageSenderName(currentOfficeMembers, senderType, senderAssociateId){
  if(senderType === "associate" ){
    let officeMembers = currentOfficeMembers.filter(
      (agentOffice) => agentOffice.associateId === senderAssociateId
    );
    return officeMembers.length > 0 ?  officeMembers[0].associateName : "Unknown Team Member";
  }else if(senderType === "system"){
    return "Auto Reply"
  }else{
    return "Customer";
  }
}

export function lastOutgoingSender(assignedAssociates, currentOfficeMembers) {
  let lastOutgoingSender;
  if (assignedAssociates && assignedAssociates.length > 0) {
    lastOutgoingSender = getAssociateName(
      assignedAssociates[0].associateID,
      currentOfficeMembers
    );
  }
  return lastOutgoingSender;
}

function getSenderDisplayName(sender) {
  if (sender.displayName) {
    return sender.displayName.name;
  }

  return undefined;
}

function getSenderDisplayLink(sender) {
  if (sender.displayName) {
    return sender.displayName.url;
  }

  return undefined;
}

export function getAssociateName(associateId, currentOfficeMembers) {
  let getSenderAssocInfo = currentOfficeMembers.filter(
    (currentOfficeMember) => currentOfficeMember.associateId === associateId
  );
  let assocName = "";
  if (getSenderAssocInfo.length > 0) {
    assocName = getSenderAssocInfo[0].associateName;
  }
  //else condition is to handle a retired ATM's old message to show as Unknown Team Member
  else {
    assocName = "Unknown Team Member";
  }
  return assocName;
}

export function translateInteractionsForReducer(payload, currentOfficeMembers) {
  let cleanedData = payload.filter(
    (interaction) => interaction.lastMessage !== null
  );
  cleanedData = cleanedData.map((interaction) => {
    return translateInteractionFromModernizedStructure(
      interaction,
      currentOfficeMembers
    );
  });
  return cleanedData.sort((a, b) => b.unmaskedDate - a.unmaskedDate);
}

export function translateClientInfosForReducer(clients) {
  if (clients) {
    return clients.map((client) => {
      return {
        clientID: client.clientId,
        firstName: client.firstName,
        middleName: client.middleName,
        lastName: client.lastName,
        customerInitials: createInitials(client.firstName, client.lastName),
        doNotText: client.doNotText,
        customerOfAnotherAgent: client.customerOfAnotherAgent,
        phoneNumber: createPhoneNumberMask(client.phone),
        casl: client.casl,
        protectedPersonIndicator: client.protectedPersonIndicator,
      };
    });
  }
}

export function buildMessageObject(
  message,
  currentOfficeMembers,
  currentInteraction
) {
  return {
    interactionId: message.interactionID,
    virtualAssistantActive: message.virtualAssistantActive,
    message: message.message.body,
    messageId: message.messageID,
    messageSender: message.source.type === "sfcagent" ? "agent" : "customer",
    messageSenderId: message.sender.id,

    messageSenderName:
      message.sender.type === "associate" || message.sender.type === "system"
        ? getSenderDisplayName(message.sender) || getAssociateName(message.sender.id, currentOfficeMembers)
        : currentInteraction.customerName,

    messageSenderLink:       
      message.sender.type === "associate" || message.sender.type === "system"
      ? getSenderDisplayLink(message.sender)
      : undefined,

    messageDate: getDateString(message.createdOn),
    messageTime: getTimeString(message.createdOn),
    messageType: message.source.type,
    attachments:
      message.message.attachments &&
      message.message.attachments.length > 0 &&
      message.message.attachments.filter((filter) => {
        return !filter.Key.includes(".txt");
      }),
    createdOn: message.createdOn,
    phoneNumber: message.source.data.phone
      ? createPhoneNumberMask(message.source.data.phone)
      : "",
  };
}

export function translateMessagesForReducer(
  messages,
  currentOfficeMembers,
  currentInteraction
) {
  return messages
    .slice(0)
    .map((message) => {
      return buildMessageObject(
        message,
        currentOfficeMembers,
        currentInteraction
      );
    });
}

function setWSInteractionPhoneNumber(interactionData) {
  let phoneNumber;
  if (
    interactionData.source &&
    interactionData.source.data &&
    interactionData.source.data.phone
  ) {
    phoneNumber = interactionData.source.data.phone;
  } else if (
    interactionData.destination &&
    interactionData.destination.length > 0 &&
    interactionData.destination[0] &&
    interactionData.destination[0].data
  ) {
    phoneNumber = interactionData.destination[0].data.phone;
  }
  return phoneNumber;
}

export function translateWSInteractionForReducer(response) {
  let interaction = response.data;
  let phoneNumber = "";
  let unmaskedPhoneNumber = "";
  //Below if condition is Agent-initiated new interaction does not have a interaction.source.phone
  if (interaction && interaction.source) {
    unmaskedPhoneNumber = setWSInteractionPhoneNumber(interaction);
    phoneNumber = createPhoneNumberMask(unmaskedPhoneNumber);
  }
  //Below if condition is for Multi-match Customer-initiated new interaction
  let multiMatchClientIDs = [];
  if (response.multiMatchInfoList) {
    multiMatchClientIDs = translateMultiMatchInfo(
      response.multiMatchInfoList,
      response.clientInfos
    );
  }

  return {
    archived: false,
    associateId: interaction.ownerAgent.associateID,
    customerInitials: response.newClientInfo
      ? createInitials(
          response.newClientInfo.firstName,
          response.newClientInfo.lastName
        )
      : "?",
    customerName: response.newClientInfo
      ? response.newClientInfo.firstName + " " + response.newClientInfo.lastName
      : phoneNumber,
    interactionId: interaction.interactionID,
    messageDate: convertEpochToDate(interaction.createdOn),
    messagePreviewText: interaction.message.body,
    unmaskedPhoneNumber: unmaskedPhoneNumber,
    isMessageViewed: interaction.sender.type !== "associate" ? false : true,
    assignedAssociates: "",
    unreadClientMessageCount: interaction.sender.type !== "associate" ? 1 : 0,
    clientId: interaction.ownerClient.clientID,
    clientType: interaction.ownerClient.type,
    multiMatchClientIDs: multiMatchClientIDs,
    lastOutgoingSender: "Customer",
    phoneNumber: phoneNumber,
    hidden: {
      value: interaction.hidden ? interaction.hidden.value : false,
    },
    assigned: interaction.assigned ? interaction.assigned : {value: false},
  };
}

export function translateWSMessage(currentInteraction) {
  return {
    //We will fetch this UUID from the AWS Service
    messageID: null,
    destination: [
      {
        vehicleID: "",
        data: {
          phone: currentInteraction.unmaskedPhoneNumber,
        },
        type: "mms",
        meta: {
          id: "f9768924-2958-4596-88c7-a95778743bc0",
        },
      },
    ],
    interactionID: currentInteraction.interactionId,
    virtualAssistantActive: currentInteraction.virtualAssistantActive,
    message: {
      body: currentInteraction.messageBody,
    },
    ownerAgent: {
      associateID: currentInteraction.associateId,
    },
    ownerClient: {
      clientID: currentInteraction.clientId,
      type: currentInteraction.clientType,
    },
  };
}

const createDisplayName = (firstName, middleName = "", lastName) => {
  if (firstName !== "") {
    return `${lastName}, ${firstName} ${
      middleName && middleName.length > 0 ? middleName[0] : ""
    }`;
  } else {
    return `${lastName}`;
  }
};

export function translateCustomerDataAWS(customers) {
  return customers.map((customer) => {
    return {
      clientID: customer.clientID,
      firstName: customer.firstName,
      lastName: customer.lastName,
      middleName: customer.middleName,
      suffixName: customer.suffix ? customer.suffix : "",
      address: customer.address,
      city: customer.city,
      state: customer.state,
      zip: customer.zip,
      firstTextableMobilePhone: customer.firstTextableMobilePhone,
      doNotText: customer.doNotText,
      casl: customer.casl,
      customerOfAnotherAgent: customer.customerOfAnotherAgent,
      protectedPersonIndicator: customer.protectedPersonIndicator,
      displayedPhone:
        customer.firstTextableMobilePhone !== "" &&
        createPhoneNumberMask(customer.firstTextableMobilePhone),
      displayedName:
        createDisplayName(
          customer.firstName,
          customer.middleName,
          customer.lastName
        ),
      textStatus: customer.casl
        ? "C"
        : customer.doNotText
        ? "D"
        : customer.firstTextableMobilePhone === "" || customer.firstTextableMobilePhone === undefined
        ? "N"
        : "",
    };
  });
}

export function getOfficeMembersIdAndNameList(agent, atmList) {
  let OfficeMembersIDNameMap = [];
  OfficeMembersIDNameMap.push({
    associateId: agent.id,
    associateName: agent.name,
    system: false,
    userType: agent.type,  
  });
  atmList.forEach((atm) => {
    OfficeMembersIDNameMap.push({
      associateId: atm.id,
      associateName: atm.name,
      system: atm.system,
      userType: atm.type,
    });
  });
  return OfficeMembersIDNameMap;
}

export function getAgentOfficePhoneList(agentsOfficeList) {
  return agentsOfficeList.map((agentOffice) => {
    return {
      associateID: agentOffice.id,
      phone:agentOffice.businessPhone,
      displayedPhone: createPhoneNumberMask(agentOffice.businessPhone),
    }
  })
}

export function translateAgentOfficeDataList(associateInfo) {
  return associateInfo.agents.map((agentOffice) => {
    let officeMembers = getOfficeMembersIdAndNameList(
      agentOffice,
      agentOffice.atms
    );
    return {
      id: agentOffice.id,
      name: agentOffice.name,
      type: agentOffice.type,
      stateAgentCode: agentOffice.stateAgentCode,
      displayedStateAgentCode: createDisplayAgentCode(
        agentOffice.stateAgentCode
      ),
      businessPhone: agentOffice.businessPhone,
      officeMembers: officeMembers,
    };
  });
}

export function translateOfficePhone(phone) {
  return {
    displayedOfficePhone: createPhoneNumberMask(phone),
    number: phone
  };
}

export function translateInteractionForNewInteraction(payload) {
  return {
    archived: false,
    associateId: payload.officeAssociateId,
    customerInitials: createInitials(
      payload.customerDetails.firstName,
      payload.customerDetails.lastName
    ),
    customerName: createName(
      payload.customerDetails.firstName,
      payload.customerDetails.lastName,
      payload.customerDetails.displayedPhone
    ),
    customerOfAnotherAgent: payload.customerDetails.customerOfAnotherAgent,
    doNotText: payload.customerDetails.doNotText,
    casl: payload.customerDetails.casl,
    protectedPersonIndicator: payload.customerDetails.protectedPersonIndicator,
    hasAssociateId: false,
    interactionId: "tempInteractionId",
    unmaskedPhoneNumber: payload.customerDetails.firstTextableMobilePhone,
    clientId: payload.customerDetails.clientID,
    clientType: "client",
    messageDate: "",
    messagePreviewText: "[Draft]",
    messageSenderId: payload.loggedinAssociateType === "MOAAgent" ? payload.officeAssociateId : payload.loggedInAssociateId,
    messageSenderType: "associate",
    assignedAssociates: payload.loggedinAssociateType === "MOAAgent" ? payload.officeAssociateId : payload.loggedInAssociateId,
    lastOutgoingSender: payload.loggedInAssociateName,
    phoneNumber: payload.customerDetails.displayedPhone,
    unmaskedDate: "",
    isMessageViewed: true,
    ecrmMobile: payload.ecrmPhone ? payload.ecrmPhone : "",
    hidden: {
      value: false,
    },
    assigned: {
      value: true,
      assignedAssociateId: payload.loggedinAssociateType === "MOAAgent" ? payload.officeAssociateId : payload.loggedInAssociateId,
      assignedBy: { type: "associate", id: payload.loggedinAssociateType === "MOAAgent" ? payload.officeAssociateId : payload.loggedInAssociateId }, 
    },
  };
}

export function translateInteractionFromModernizedStructure(
  interaction,
  currentOfficeMembers
) {
  let lastSenderName = getLastMessageSenderName(currentOfficeMembers, interaction.lastMessage.sender.type, interaction.lastMessage.sender.id);
  
  let phone = interaction.lastMessage.source.phone
    ? interaction.lastMessage.source.phone
    : interaction.lastMessage.destination.phone;
  const lastReplierType = interaction.lastMessage.sender.type;
  let phoneNumber = createPhoneNumberMask(phone);

  let customerNameExistsInClientInfo = interaction.client.details
    ? interaction.client.details.filter(
        (client) => client && client.clientId === interaction.client.clientId
      )
    : [];

  let multiMatchClientIDs = [];
  if (interaction.client.type === "multi-match") {
    multiMatchClientIDs = translateMultiMatchInfo(
      interaction.client.multiMatchClientIDs,
      interaction.client.details
    );
  }
  return {
    archived: false,
    associateId: interaction.agentAssociateId,
    customerInitials:
      customerNameExistsInClientInfo.length > 0
        ? createInitials(
            customerNameExistsInClientInfo[0].firstName,
            customerNameExistsInClientInfo[0].lastName
          )
        : "?",
    customerName:
      customerNameExistsInClientInfo.length > 0
        ? customerNameExistsInClientInfo[0].firstName +
          " " +
          customerNameExistsInClientInfo[0].lastName
        : phoneNumber,
    customerOfAnotherAgent:
      customerNameExistsInClientInfo.length > 0
        ? customerNameExistsInClientInfo[0].customerOfAnotherAgent
        : false,
    doNotText:
      customerNameExistsInClientInfo.length > 0
        ? customerNameExistsInClientInfo[0].doNotText
        : false,
    casl:
      customerNameExistsInClientInfo.length > 0
        ? customerNameExistsInClientInfo[0].casl
        : false,
    hasAssociateId: false,
    protectedPersonIndicator:
      customerNameExistsInClientInfo.length > 0
        ? customerNameExistsInClientInfo[0].protectedPersonIndicator
        : false,
    interactionId: interaction.interactionId,
    unmaskedPhoneNumber: phone,
    clientId: interaction.client.clientId,
    multiMatchClientIDs: multiMatchClientIDs,
    clientType: interaction.client.type,
    messageDate: convertEpochToDate(interaction.updatedOn),
    messagePreviewText: interaction.lastMessage.messageContent.text,
    assignedAssociates: interaction.assignedAssociates
      ? interaction.assignedAssociates.length > 0
        ? interaction.assignedAssociates[0].associateID
        : ""
      : "",
    lastOutgoingSender: interaction.repliedTo.value ?  lastSenderName : lastReplierType === "system" ? "Auto Reply" : "Customer", /* lastOutgoingSender(
      interaction.assignedAssociates,
      currentOfficeMembers
    ), */
    lastReplierType,
    phoneNumber: phoneNumber,
    unmaskedDate: new Date(interaction.updatedOn),
    isMessageViewed: interaction.read.value || lastReplierType === "associate",
    unreadClientMessageCount:
      interaction.readers &&
      Array.isArray(interaction.readers) &&
      interaction.readers.length >= 1
        ? interaction.readers[0].unreadClientMessageCount
        : 0,
    messageDeliveryStatus: "",
    ecrmMobile: "",
    starred: interaction.followUp,
    virtualAssistantActive: interaction.virtualAssistantActive,
    virtualAssistantFollowup: virtualAssistantFollowup(interaction.followUp),
    ownerAgentName: getAssociateName(
      interaction.agentAssociateId,
      currentOfficeMembers
    ),
    hidden: {
      value: interaction.hidden ? interaction.hidden.value : false,
    },
    fuzzySearchResults: interaction.fuzzySearchResults,
    assigned: interaction.assigned,
  };
}

export function createWSEmptyMessage(currentInteraction) {
  return {
    messageID: null,
    destination: [
      {
        data: {
          phone: currentInteraction.unmaskedPhoneNumber,
        },
        type: "mms",
        meta: {},
      },
    ],
    interactionID: "",
    message: {
      body: "",
      attachments: [],
    },
    ownerAgent: {
      associateID: currentInteraction.associateId,
    },
    ownerClient: {
      clientID: currentInteraction.clientId,
      type: currentInteraction.clientType,
    },
  };
}

export function getOfficeMembers(
  officeAssociateId,
  agentsOfficeList,
  loggedInAssociateId
) {
  let officeMembers = agentsOfficeList.filter(
    (agentOffice) => agentOffice.id === officeAssociateId
  )[0].officeMembers;

  if (officeMembers && officeMembers.length) {
    officeMembers.sort((a, b) =>
      a.associateName.localeCompare(b.associateName)
    );

    let indexOfLoggedInUser = officeMembers.findIndex(
      (officeMember) => (officeMember.associateId === loggedInAssociateId)
    );
    if(indexOfLoggedInUser < 0){
      indexOfLoggedInUser = officeMembers.findIndex(
        (officeMember) => (officeMember.associateId === officeAssociateId)
      );
    }

    const loggedInUser = officeMembers[indexOfLoggedInUser];
    officeMembers[indexOfLoggedInUser].associateName = "Me";
    officeMembers.splice(indexOfLoggedInUser, 1);
    officeMembers.unshift(loggedInUser);
  }
  return officeMembers;
}

export function getClientInfoDetails(payload, clientInfos) {
  
  let clientId = payload.ownerClient ? payload.ownerClient.clientID : payload.client.clientId;
  let type = payload.ownerClient ? payload.ownerClient.type : payload.client.type;
  let clientInfoFound = clientInfos.find((clientInfo) => {
    if (Array.isArray(clientInfo)) {
      return clientInfo.some(
        (client) => client.clientID === clientId
      );
    } else {
      return clientInfo.clientID === clientId;
    }
  });


  let phone = payload.source ? payload.source.data.phone : 
              payload.lastsfcMessage ? payload.lastsfcMessage.source.data.phone : 
              payload.lastMessage ? 
              (payload.lastMessage.source.phone ? payload.lastMessage.source.phone : payload.lastMessage.destination.phone) : 
              payload.messages[0].source.data.phone
  return {
    customerName: createCustomerName(
      clientInfoFound,
      phone,
      clientId
    ),
    customerInitials: createCustomerInitials(
      clientInfoFound,
      clientId,
      type
    ),
    customerOfAnotherAgent: isCustomerOfAnotherAgent(
      clientInfoFound,
      clientId
    ),
    doNotText: isCustomerDoNotText(
      clientInfoFound,
      clientId
    ),
    casl: isCustomerCasl(clientInfoFound, clientId),
  };
}

export function addNewClientDataToClientInfos(newClientData) {
  let phoneNumber =
    "(" +
    newClientData.firstTextableMobilePhone.slice(0, 3) +
    ") " +
    newClientData.firstTextableMobilePhone.slice(3, 6) +
    "-" +
    newClientData.firstTextableMobilePhone.slice(6);
  let customerInitials = "";
  if (newClientData) {
    if (newClientData.firstName.trim().length !== 0)
      customerInitials = newClientData.firstName[0];
    if (newClientData.lastName.trim().length !== 0)
      customerInitials = customerInitials + newClientData.lastName[0];
  }
  return {
    clientID: newClientData.clientID,
    firstName: newClientData.firstName ? newClientData.firstName : "",
    middleName: newClientData.middleName,
    lastName: newClientData.lastName,
    customerInitials: customerInitials,
    doNotText: newClientData.doNotText,
    customerOfAnotherAgent: newClientData.customerOfAnotherAgent,
    phoneNumber: phoneNumber,
    casl: newClientData.casl,
  };
}

export function virtualAssistantFollowup(starred) {
  if (starred && starred.status && starred.updatedBy === "HERCULES") {
    return true;
  }
  return false;
}

const createCustomerName = (clientInfo, customerPhoneNumber, clientId) => {
  let customerName;

  if (Array.isArray(clientInfo)) {
    let tempClient = clientInfo.filter((client) => {
      return client.clientID === clientId;
    })[0];
    customerName = tempClient.firstName + " " + tempClient.lastName;
  } else if (clientInfo && (clientInfo.firstName || clientInfo.lastName)) {
    customerName = clientInfo.firstName + " " + clientInfo.lastName;
  } else if (customerPhoneNumber) {
    customerName = createPhoneNumberMask(customerPhoneNumber);
  } else {
    customerName = "Unknown";
  }

  return customerName;
};

const createCustomerInitials = (clientInfo, clientId, type) => {
  let customerInitials;

  if (type === "multi-match") customerInitials = "?";
  else if (Array.isArray(clientInfo)) {
    let tempClient = clientInfo.filter((client) => {
      return client.clientID === clientId;
    })[0];
    customerInitials = tempClient.customerInitials;
  } else if (clientInfo && clientInfo.customerInitials) {
    customerInitials = clientInfo.customerInitials;
  } else {
    customerInitials = "?";
  }

  return customerInitials;
};

const isCustomerOfAnotherAgent = (clientInfo, clientId) => {
  let customerOfAnotherAgent = false;

  if (Array.isArray(clientInfo)) {
    let tempClient = clientInfo.filter((client) => {
      return client.clientID === clientId;
    })[0];
    customerOfAnotherAgent = tempClient.customerOfAnotherAgent;
  } else if (clientInfo && clientInfo.customerOfAnotherAgent) {
    customerOfAnotherAgent = clientInfo.customerOfAnotherAgent;
  }

  return customerOfAnotherAgent;
};

const isCustomerDoNotText = (clientInfo, clientId) => {
  let doNotText = false;

  if (Array.isArray(clientInfo)) {
    let tempClient = clientInfo.filter((client) => {
      return client.clientID === clientId;
    })[0];
    doNotText = tempClient.doNotText;
  } else if (clientInfo && clientInfo.doNotText) {
    doNotText = clientInfo.doNotText;
  }
  return doNotText;
};

const isCustomerCasl = (clientInfo, clientId) => {
  let casl = false;

  if (Array.isArray(clientInfo)) {
    let tempClient = clientInfo.filter((client) => {
      return client.clientID === clientId;
    })[0];
    casl = tempClient.casl;
  } else if (clientInfo && clientInfo.casl) {
    casl = clientInfo.casl;
  }

  return casl;
};

import { combineReducers } from "redux";
import interactionsReducer from "./interactionsReducer";
import authReducer from "./authReducer";
import customerSearchReducer from "./customerSearchReducer";
import officeSettingsReducer from "./officeSettingsReducer";
import userInfoReducer from "./userInfoReducer";
import webSocketReducer from "./webSocketReducer";
import customTemplatesReducer from "./customTemplatesReducer";
import userSettingsReducer from "./userSettingsReducer";
import applicationSettingsReducer from "./applicationSettingsReducer";


export default combineReducers({
  interactionsReducer: interactionsReducer,
  authReducer: authReducer,
  customerSearchReducer: customerSearchReducer,
  officeSettingsReducer: officeSettingsReducer,
  userInfoReducer: userInfoReducer,
  webSocketReducer: webSocketReducer,
  customTemplatesReducer: customTemplatesReducer,
  userSettingsReducer: userSettingsReducer,
  applicationSettingsReducer: applicationSettingsReducer
});

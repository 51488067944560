import axios from "axios";
import { logUIErrors } from "./interactionsActions";
import {
  GET_APP_SETTINGS,
} from "./types";

export const fetchSettings = (application) => (dispatch) => {
  axios
  .post(
    process.env.REACT_APP_APPSYNC_API_SETTINGS_URL,
    {
      query: `query getSettings($application: String!) {
      getSettings(application: $application) {
        uiAlert {
          content
          title
        }
      }
    }`,
      variables: {
        application: `${application}`
      }
    }
  )
  .then((response) => {
    dispatch({
      payload: response.data.data.getSettings,
      type: GET_APP_SETTINGS
    })
  })
  .catch((error) => {
    console.log("Error calling appsync settings end point = " + error)
    let err;
    if (error.response) {
      // The request was made and the server responded with a status code
      err = error.response;
      err.stack = undefined;
      err.config = undefined;
    } else if (error.request) {
      // The request was made but no response was received
      err = error.request;
    } else {
      // Something happened in setting up the request that triggered an error
      err = error.message;
    }
    dispatch(
      logUIErrors("application settings", err, "Fetch Application Settings", false)
    );     
  });
};
const settingsVersion = 'v1';

const getUserOfficeSettingsKey = (userAssociateId, agentAssociateId, phoneNumber) => {
    return `${settingsVersion}_${userAssociateId}_${agentAssociateId}_${phoneNumber}`;
};

const getUserGlobalSettingsKey = () => {
    return `${settingsVersion}_sfconnect_global_settings`;
};

const getGlobalSettings = () => {
    const settings = localStorage.getItem(getUserGlobalSettingsKey());

    if (settings) {
        return JSON.parse(settings);
    }    
    return {};
};

const getOfficeSettings = (userAssociateId, agentAssociateId, phoneNumber) => {
    const settings = localStorage.getItem(getUserOfficeSettingsKey(userAssociateId, agentAssociateId, phoneNumber));

    if (settings) {
        return JSON.parse(settings);
    }    
    return {
        office: {
            associateId: agentAssociateId,
        }
    };
};

const saveOfficeSettings = (userAssociateId, agentAssociateId, phoneNumber, key, value) => {
    const settings = getOfficeSettings(userAssociateId, agentAssociateId, phoneNumber);

    if (!settings.office) {
        settings.office = {
            associateId: agentAssociateId
        };
    }

    settings.office[key] = value;
    localStorage.setItem(getUserOfficeSettingsKey(userAssociateId, agentAssociateId, phoneNumber), JSON.stringify(settings));

    return getOfficeSettings(agentAssociateId, phoneNumber);
};

module.exports = {
    getGlobalSettings,
    getOfficeSettings,
    saveOfficeSettings,
}
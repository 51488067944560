import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CustomerSearchByName from "./CustomerSearchByName";
import CustomerSearchByPhone from "./CustomerSearchByPhone";
import Breadcrumb from "../../components/Breadcrumb";

import { fetchCustomersByName } from '../../redux/actions/customerSearchAction';
import { fetchCustomersByPhoneNumber } from '../../redux/actions/customerSearchAction';
import { clearCustomerRows } from '../../redux/actions/customerSearchAction';
import { Component } from "react";

class CustomerSearchCriteria extends Component {

    constructor(props){
        super(props);

        this.onCustomerSearchClick=this.onCustomerSearchClick.bind(this);
        this.onDropdownSwitch=this.onDropdownSwitch.bind(this);
        this.handleChangeValue=this.handleChangeValue.bind(this);

        this.state = {
            searchCriteria: "Name",
            exactMatch: false,
            inputFieldValue: {
                lastName: "", 
                firstName:"", 
                phoneNumber:"",
            },
        };
    }

    componentDidMount() {
        if (window.oneX) {
            window.oneX.addElement(this.refs.searchCriteriaDropDown)
        }
    }

    onCustomerSearchClick(event) {
        event.preventDefault();
        this.props.actions.clearCustomerRows(); 
        if(this.state.searchCriteria === "Name"){     
            this.props.actions.fetchCustomersByName(this.props.officeAssociateId, encodeURIComponent(this.state.inputFieldValue.lastName.replace(/[^a-zA-Z0-9.'\- ]/g, "").trim()), this.state.exactMatch, encodeURIComponent(this.state.inputFieldValue.firstName.replace(/[^a-zA-Z0-9.'\- ]/g, "").trim()));      
        } else {          
            const modifiedPhoneNumber = this.state.inputFieldValue.phoneNumber.replace(/[^0-9 ]/g, "");
            this.props.actions.fetchCustomersByPhoneNumber(this.props.officeAssociateId, modifiedPhoneNumber);
        }
      };
    
    onDropdownSwitch(event) {
    this.props.actions.clearCustomerRows();
    this.clearFields();
    this.setState({searchCriteria: event.target.value});
    };

    clearFields(e) {
        this.setState({
          inputFieldValue: {
              lastName: "", 
              firstName:"", 
              phoneNumber:"",
              }
          })
      }        
        
    handleChangeValue(event) {
        this.setState({
            inputFieldValue: ({
                ...this.state.inputFieldValue,
                [event.target.name]: event.target.value,         
            })
        })
    }


    render() {
     const { history } = this.props;
    return (     
        <div >
            <Breadcrumb
                        title="Message In Tray"
                        history={history}
                      />

            <div ref={'searchCriteriaDropDown'} className="-oneX -oneX-container search-criteria-container">
             <form>
               <div className="-oneX-row">
                 <div className="-oneX-col-5 -oneX-col-md-3 -oneX-col-lg-2">
                  <label htmlFor="popDropdown" className="-oneX-dropdown__floatingLabel"></label>
                   <select id="popDropdown" type="text" className="-oneX-dropdown dropdown-row" aria-label="select filter by" aria-required="true" onChange={this.onDropdownSwitch}
                   >
                    <option value="Name">Name</option>
                    <option value="Mobile Phone">Mobile Phone</option>
                  </select>
                  
                </div>

                <div className="-oneX-col-6 -oneX-col-md-6 -oneX-col-lg-8">
                  {this.state.searchCriteria === "Name" ?
                  <CustomerSearchByName 
                    inputFieldFirstName={this.state.inputFieldValue.firstName} 
                    inputFieldLastName={this.state.inputFieldValue.lastName}
                    onChangeFieldExactMatch={() => this.setState({exactMatch: !this.state.exactMatch})
                    } 
                    onChangeValue={this.handleChangeValue}
                    />
                   : <CustomerSearchByPhone 
                        inputFieldPhoneNumber={this.state.inputFieldValue.phoneNumber} 
                        onChangeValue={this.handleChangeValue}
                        />}
                </div>
               
                <div className="-oneX-col-5 -oneX-offset-6 -oneX-col-md-3 -oneX-offset-md-0 -oneX-col-lg-2 -oneX-offset-lg-0">
                  <button type="submit" id="customerSearchBtn" className="-oneX-btn-primary" onClick={this.onCustomerSearchClick} disabled={this.state.inputFieldValue.lastName.replace(/[^a-zA-Z0-9]/g, "") === "" && this.state.inputFieldValue.phoneNumber.length < 14}>
                    Search
                  </button>
                </div>
                {this.state.inputFieldValue.lastName !== "" && this.state.inputFieldValue.lastName.replace(/[^a-zA-Z0-9]/g, "") === "" && 
                  (<div>Please enter at least an alphabet in 'Last Name / Org' field to search customers.</div>)}
              </div>
            </form>
          </div>
        </div>
        );
    }
}  

function mapStateToProps (state){
    return{
        token: state.authReducer.token,
        officeAssociateId: state.userInfoReducer.officeAssociateId,
    }; 
}

function mapDispatchToProps(dispatch) {
  return{
    actions: bindActionCreators(
      {
        fetchCustomersByName,
        fetchCustomersByPhoneNumber,
        clearCustomerRows
      },
      dispatch
    )
  };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomerSearchCriteria);
import {
  GET_OFFICE_PREFERENCES,
  RESET_FETCHED_VALUES,
  PATCH_OFFICE_PREFERENCES,
  SHOW_GEAR_ICON,
  GET_SFO_AGENT_OFFICE,
  RETIRE_AN_AGENT_OFFICE,
  CREAT_AN_AGENT_OFFICE
} from "../actions/types";
import { translateOfficePhone } from "../../utils/translators";

const intialState = {
  isFetched: false,
  oooEnabled: "",
  officePhone: "",
  isAgentEnabled: false,
  isFetchedButNoOffice: false,
  showProvisionError: false,
  showGearIcon: true,
  officeAdministrators: [],
  requireOfficeAdministratorToAssign: false,
  outboundTextAutoAssign: {
    value: false
  }
};

export default function (state = intialState, action) {
  switch (action.type) {
    case GET_OFFICE_PREFERENCES: {
      if (Object.keys(action.payload).length !== 0) {
        return {
          ...state,
          officeAssociateId: action.payload.agentAssociateId,
          isFetched: true,
          officePhone: translateOfficePhone(action.payload.officePhoneNumber),
          oooEnabled: action.payload.oooEnabled,
          virtualAssistantEnabled: action.payload.virtualAssistantEnabled,
          emailRecipients: action.payload.emailRecipients,
          altIDEnabled: action.payload.optedIn,
          officeAdministrators: action.payload.officeAdministrators,
          requireOfficeAdministratorToAssign:
            action.payload.requireOfficeAdministratorToAssign,
          outboundTextAutoAssign: action.payload.outboundTextAutoAssign !== undefined ? action.payload.outboundTextAutoAssign : { value: false },
          sfoOfficeStatus: false,
          typeOfOffice: action.payload.typeOfOffice,
          showGearIcon: true
        };
      } else {
        return {
          ...state,
          isFetched: true,
          isFetchedButNoOffice: true,
          sfoOfficeStatus: false,
        };
      }
    }
    case GET_SFO_AGENT_OFFICE: {
      if (Object.keys(action.payload).length !== 0) {
        return {
          ...state,
          sfoOfficeAssociateId: action.payload.agentID,
          sfoOfficePhone: translateOfficePhone(action.payload.officePhoneNumber),
          sfoOfficeStatus: true,
        };
      } else {
        return {
          ...state,
          sfoOfficeStatus: false,
        };
      }
    }
    case RETIRE_AN_AGENT_OFFICE: {
      if (Object.keys(action.payload).length !== 0) {
        return {
          ...state,
          sfoOfficeAssociateId: "",
          sfoOfficePhone: "",
          sfoOfficeStatus: false,
        };
      } else {
        return {
          ...state,
          sfoOfficeStatus: false,
        };
      }
    }
    case CREAT_AN_AGENT_OFFICE: {
      if (Object.keys(action.payload).length !== 0) {
        return {
          ...state,
          sfoOfficeAssociateId: action.payload.agentAssociateId,
          sfoOfficePhone: action.payload.officePhoneNumber,
          sfoOfficeStatus: true,
        };
      } else {
        return {
          ...state,
          sfoOfficeStatus: false,
        };
      }
    }
    case PATCH_OFFICE_PREFERENCES: {
      return {
        ...state,
        oooEnabled: action.payload.oooEnabled,
        isAgentEnabled: true,
        emailRecipients: action.payload.emailRecipients,
        showProvisionError: false,
        officeAdministrators: action.payload.officeAdministrators,
        requireOfficeAdministratorToAssign:
          action.payload.requireOfficeAdministratorToAssign,
        outboundTextAutoAssign: action.payload.outboundTextAutoAssign !== undefined ? action.payload.outboundTextAutoAssign : { value: false },
        typeOfOffice: action.payload.typeOfOffice,
        isFetchedButNoOffice: false,
        showGearIcon: true,
        sfoOfficeStatus: action.payload.typeOfOffice === "SFO" ? true : false
      };
    }

    case RESET_FETCHED_VALUES: {
      return {
        ...state,
        isFetched: false,
        isFetchedButNoOffice: false,
        isAgentEnabled: false,
      };
    }

    case SHOW_GEAR_ICON: {
      return {
        ...state,
        showGearIcon: action.payload,
      };
    }

    default:
      return state;
  }
}

import axios from "axios";
import { 
  GET_OFFICE_PREFERENCES, 
  PATCH_OFFICE_PREFERENCES, 
  GENERIC_ERROR, 
  GET_SFO_AGENT_OFFICE, 
  RETIRE_AN_AGENT_OFFICE,
} from "./types";
import { logUIErrors } from "./interactionsActions";

export const getOfficePreferences =
  (officeAssociateId, phoneNumber, isSFOSearch) => (dispatch) => {
    axios
      .get(
        process.env.REACT_APP_AWS_API_URL + `agents/${officeAssociateId}/phones/${phoneNumber.trim()}/preferences`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if(response.data === ""){
          axios.get(
            process.env.REACT_APP_AWS_API_URL + `officePreferences?agentAssociateId=${officeAssociateId}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if(response.data.length === 0){
              const inputData = "Get Office Preference with AssociateID input data: " + "Request URL : "+process.env.REACT_APP_AWS_API_URL +`officePreferences?agentAssociateId=${officeAssociateId}`;
              dispatch(logUIErrors(officeAssociateId, "", inputData, false, "INFO"));
            }
            if(isSFOSearch){
              dispatch({ type: GET_SFO_AGENT_OFFICE, payload: response.data.length >0 ? response.data[0] : ""});
            }else{
              dispatch({ type: GET_OFFICE_PREFERENCES, payload: response.data.length >0 ? response.data[0] : ""});
            }
          })
          .catch((error) => {
            dispatch(logUIErrors(officeAssociateId, error, "Get Office Preference with associateID", true));
          });
        }else{
          if(isSFOSearch){
            dispatch({ type: GET_SFO_AGENT_OFFICE, payload: response.data});
          }else{
            dispatch({ type: GET_OFFICE_PREFERENCES, payload: response.data});
          }  
        }
      })
      .catch((error) => {
        dispatch(logUIErrors(officeAssociateId, error, "Get Office Preference", true));
      });
};

//optin an agent
export const optInAgentOffice = (officeAssociateId, phoneNumber, loggedInAssociateId, sfoOfficeStatus) => (dispatch) => {
  var data = "";
  if(sfoOfficeStatus){
    data ={
      "createdBy": loggedInAssociateId,
      "options" : {
        "typeOfOffice": "SFO",
        "servicingAgentAssociateId": loggedInAssociateId
      }
    }
  }else {
    data = {
      "createdBy": officeAssociateId,
    } 
  }
  axios
    .post(
      process.env.REACT_APP_AWS_API_URL +`agents/${officeAssociateId}/phones/${phoneNumber.trim()}/preferences`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data === ""){
        const inputData = "Opt-in an Agent input data: " + "Request URL : "+process.env.REACT_APP_AWS_API_URL +`agents/${officeAssociateId}/phones/${phoneNumber.trim()}/preferences`+ "input Data: "+ data;
        dispatch(logUIErrors(officeAssociateId, "", inputData, false, "INFO"));
      }
      dispatch({ type: PATCH_OFFICE_PREFERENCES, payload: response.data });
    })
    .catch((error) => {
      dispatch(logUIErrors(officeAssociateId, error, "Opt-in an Agent", true));
    });
};

//email Notification update
//Office Admin Settings update
// OOO settings update
//setOutboundTextAutoAssign update
export const updateOfficePreferences = (officeAssociateId, phoneNumber, loggedInUser, data) => (dispatch) => {
  const patchData = {
    attributesToUpdate: {
      ...data,
      updatedBy: loggedInUser
    }
  };

  axios
    .patch(
      process.env.REACT_APP_AWS_API_URL + `agents/${officeAssociateId}/phones/${phoneNumber.trim()}/preferences`,
      patchData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data === ""){
        const inputData = "Update Office Preference input data: " + "Request URL : "+process.env.REACT_APP_AWS_API_URL +`agents/${officeAssociateId}/phones/${phoneNumber.trim()}/preferences`+ "input Data: "+ patchData;
        dispatch(logUIErrors(officeAssociateId, "", inputData, false, "INFO"));
      }
      dispatch({ type: PATCH_OFFICE_PREFERENCES, payload: response.data });
    })
    .catch((error) => {
      dispatch(logUIErrors(officeAssociateId, error, "Update Office Preference", true));
    });
};

export const retireAnAgent = (officeAssociateId, retireAgentID) => (dispatch) => {
  const patchData = {
    attributesToUpdate: {
      updatedBy: officeAssociateId
    },
    retirementInputType: "agentAssociateIds",
    retirementInputValues: [retireAgentID]
  };

  axios
    .post(
      process.env.REACT_APP_AWS_API_URL + `agentRetirements`,
      patchData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({ type: RETIRE_AN_AGENT_OFFICE, payload: response.data });
    })
    .catch((error) => {
      dispatch(logUIErrors(officeAssociateId, error, "Agent retirement/optout from SFConnect", true));
    });
};

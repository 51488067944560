import React, {useState} from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useSelector, useDispatch } from "react-redux";
import { retireAnAgent, optInAgentOffice, getOfficePreferences } from "../../redux/actions/officeSettingsActions";
import { toast } from "react-toastify";
import { createPhoneNumberMask} from "../../utils/translators.js";


export default function AddRemoveOffice(panel) {  
  const dispatch = useDispatch();

  const officeAssociateId = useSelector(
    (state) => state.officeSettingsReducer.officeAssociateId
  );

  const loggedInAssociateId = useSelector(
    (state) => state.userInfoReducer.loggedInAssociateId
  );

  const associateInfo = useSelector( 
    (state) => state.userInfoReducer.agentsOfficeList
  );

  const slectedOffice = associateInfo.find((element) => {
    return element.id === officeAssociateId;
  });

  const sfoOfficeToActivate = associateInfo.find((element) => {
    return element.name.startsWith("SFO");
  });
    
  const sfoOfficeStatus = useSelector(
    (state) => state.officeSettingsReducer.sfoOfficeStatus  
  );
  
  
  const setPrimaryOfficeLable = () => {
    if(slectedOffice &&  slectedOffice !== undefined ){
      return(slectedOffice.displayedStateAgentCode + " - " + slectedOffice.name);
    }
  }
  const setSFOOfficeLable = () => {
    if(sfoOfficeToActivate &&  sfoOfficeToActivate !== undefined){
      var stageAgentCodelocal = sfoOfficeToActivate.displayedStateAgentCode === undefined ? sfoOfficeToActivate.stateAgentCode.substring(2) : sfoOfficeToActivate.displayedStateAgentCode;
      return (stageAgentCodelocal + " - " + sfoOfficeToActivate.name);
    }
  }
  
  const isPrimaryOffice = () => {
    return slectedOffice === "undefined" ? false : true;
  }
  
  const isSFOOfficeActive = () => {
    if(!sfoOfficeStatus){
      if(sfoOfficeToActivate &&  sfoOfficeToActivate !== undefined){
        dispatch(
          getOfficePreferences(sfoOfficeToActivate.id, sfoOfficeToActivate.businessPhone, true)
        );
      }
    }
  };

  const onClickOptInOut = () => {
    const toastId = toast.info(
      <div>
        {sfoOfficeStatus &&
          <p>Are you sure you want to Opt-out this <b> {createPhoneNumberMask(sfoOfficeToActivate.businessPhone)} </b> office phone number ?</p>
        }
        {!sfoOfficeStatus &&
          <p>Are you sure you want to Opt-in this <b> {createPhoneNumberMask(sfoOfficeToActivate.businessPhone)} </b> office phone number ?</p>
        }
        
        <button onClick={() => optinOffice(toastId)}>Yes</button>
        &nbsp;&nbsp;
        <button onClick={() => toast.dismiss(toastId)}>No</button>
      </div>,
      {
        position: "bottom-right",
        autoClose: false,
        closeOnClick: false,
        draggable: true,
        pauseOnHover: true,
        progress: undefined,
        closeButton: true,
        width: "3000px"
      }
    );
  };

  const optinOffice = (toastId) => {
    if(sfoOfficeStatus){
      dispatch(
        retireAnAgent(officeAssociateId, sfoOfficeToActivate.id)
      );
      toast.dismiss(toastId);
    }else if(!sfoOfficeStatus){
      dispatch(
        optInAgentOffice(sfoOfficeToActivate.id, sfoOfficeToActivate.businessPhone, loggedInAssociateId, !sfoOfficeStatus)
      );
      toast.dismiss(toastId);
    }
  };

  return (
    <>
      <p className="panel-details">
        <p>Agents can activate and de-activate additional assigned office codes by clicking on the blue toggle circle next to the pertinent office.</p>
          <p>&nbsp;&nbsp;&nbsp;•	Click to move the blue toggle circle to the right to activate that office code.</p>
          <p>&nbsp;&nbsp;&nbsp;•	Click to move the blue toggle circle to the left to deactivate that office code.</p>
        <br></br>
        <b>NOTE:</b> 
        <p>&nbsp;&nbsp;&nbsp;•	Deactivating a number opts that office out of SF Connect. </p>
        <p>&nbsp;&nbsp;&nbsp;•	Deactivating a number will trigger an archive of all SF Connect conversations for that office to the associated ECRM accounts.</p>
        <p>&nbsp;&nbsp;&nbsp;•	The Legacy/original office can only be deactivated by contacting Support Center.</p>
      </p>
      <br />
      <br />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={isPrimaryOffice()}
              //onChange={optinOffice}
              disabled={true}
            />
          }
          label= {setPrimaryOfficeLable()} 
        />
        { sfoOfficeToActivate &&  sfoOfficeToActivate !== undefined && loggedInAssociateId === officeAssociateId &&
          <FormControlLabel
          control={
            <Switch
              checked={isSFOOfficeActive() || sfoOfficeStatus}
              onChange={onClickOptInOut}
              disabled={false}
            />
          }
          label= {setSFOOfficeLable()} 
        />}
      </FormGroup>
    </>
  );
}
import {
  GET_APP_SETTINGS,
  SET_CONNECTED_TO_API_APPSYNC_WEBSOCKET
} from "../redux/actions/types.js";

import {
  dispatchWebsocketData,
  logWebSocketData
} from "../redux/actions/webSocketActions.js";

import axios from "axios";

let authorization = null;
const onAddSettings = "onAddSettings";
const onUpdateSettings = "onUpdateSettings";

axios.interceptors.response.use(
  function (response) {
    authorization = response.config.headers.Authorization;

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const apiAppsyncWebsocketHandler = {
  appsyncConnectionAttemptNumber: 0,
  resetToken: function (token) {
    this.token = authorization;
  },

  connect: function (endpoint, agentAssociateId, loggedInAssociateId) {
    const token = authorization;

    const url = getWebsocketUrl(endpoint, token);

    const websocket = new WebSocket(url, ["graphql-ws"]);

    websocket.addEventListener("open", () => {
      this.appsyncConnectionAttemptNumber++;
      if (this.appsyncConnectionAttemptNumber < 50) {
        websocket.send(
          JSON.stringify({
            type: "connection_init"
          })
        );
      }
    });

    websocket.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);
      switch (message.type) {
        case "connection_ack":
          startSubscription(websocket, token, onAddSettings);
          startSubscription(websocket, token, onUpdateSettings);
          break;
        case "start_ack":
          break;
        case "error":
          logWebSocketData(
            agentAssociateId,
            `Appsync WebSocket error ${JSON.stringify(message.payload)}`,
            `Error occurred with Appsync WebSocket connection for user with associateId ${loggedInAssociateId}.`,
            "ERROR"
          );
          websocket.close();
          dispatchWebsocketData(SET_CONNECTED_TO_API_APPSYNC_WEBSOCKET, false);
          break;
        case "data":
          const response =
            message.payload.data.onAddSettings ||
            message.payload.data.onUpdateSettings;
          if (response && response.uiAlert) {
            dispatchWebsocketData(GET_APP_SETTINGS, response);
          }
          break;
        case "connection_error":
          websocket.close();
          dispatchWebsocketData(SET_CONNECTED_TO_API_APPSYNC_WEBSOCKET, false);
          logWebSocketData(
            agentAssociateId,
            `Appsync WebSocket connection_error ${JSON.stringify(
              message.payload
            )}`,
            `Error occurred during initial connection to Appsync WebSocket for user with associateId ${loggedInAssociateId}.`,
            "ERROR"
          );
          break;
        default:
          break;
      }
    });
  }
};

function getWebsocketUrl(endpoint, token) {
  const header = encodeAppSyncCredentials(token);
  const payload = window.btoa(JSON.stringify({}));

  const url = `${endpoint}?header=${header}&payload=${payload}`;

  return url;
}

function encodeAppSyncCredentials(token) {
  const creds = {
    host: process.env.REACT_APP_API_APPSYNC_WEBSOCKET_HOST,
    Authorization: token
  };
  const b64Creds = window.btoa(JSON.stringify(creds));

  return b64Creds;
}

function startSubscription(websocket, token, subscriptionAction) {
  const subscribeMessage = {
    id: window.crypto.randomUUID(),
    type: "start",
    payload: {
      data: JSON.stringify({
        query: `subscription Alert {
            ${subscriptionAction} (application: "sfconnect") {
              uiAlert {
                content
                title
              } 
            }
        }`
      }),
      extensions: {
        authorization: {
          Authorization: token,
          host: process.env.REACT_APP_API_APPSYNC_WEBSOCKET_HOST
        }
      }
    }
  };
  websocket.send(JSON.stringify(subscribeMessage));
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchMessages,
  patchInteractionStarredSetting,
  patchInteractionReadSetting,
  mainAction,
} from "../../redux/actions/interactionsActions";
import { GO_BACK_TO_DRAFT_MESSAGE } from "redux/actions/types.js";
import Hide from "../Message/Hide";
import Modal from "../CustomerSearch/Modal";
import Archive from "../Message/Archive";
import MatchHighlight from "../Common/matchHighlight";
import Assign from "../Message/Assign";

class ChatCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archive: {
        interactionId: null,
        clientData: null,
      },

      swipe: {
        progress: 0,
        direction: "",
      },
    };
  }

  componentDidMount() {
    if (window.oneX) {
      window.oneX.Icons.init();
    }

    this.setState({
      archive: {
        interactionId: this.props.interactionId,
        clientData: this._clientDataForArchive,
      },
    });
  }

  onSwipeArchive(_interactionId) {
    window.oneX.Modal.showModal(this, "oneX-modal-archive-" + _interactionId);
  }

  render() {
    //redundancies in what is passed in - a lot of these variables already exist in currentInteraction,
    //but they are passed in many different ways
    let {
      customerName,
      clientId,
      phoneNumber,
      messageDate,
      messagePreview,
      interactionId,
      currentInteractionId,
      isMessageViewed,
      lastOutgoingSender,
      starred,
      protectedPersonIndicator,
      virtualAssistantActive,
      virtualAssistantFollowup,
      hidden,
      assigned,
      fuzzySearchResults,
      lastReplierType,
    } = this.props;
    const _clientDataForArchive = {
      clientId: clientId,
      customerName: customerName,
      phoneNumber: phoneNumber,
    };

    //redundant variables passed in since they already are in scope
    const handleChange = (event, interactionId, clientId) => {
      if (
        this.state.swipe.direction === "" &&
        event.target.nodeName !== "BUTTON"
      ) {
        if (interactionId !== "tempInteractionId") {
          //Mark interaction as read before fetching the message
          this.props.actions.patchInteractionReadSetting(
            this.props.officeAssociateId,
            interactionId,
            clientId,
            0,
            this.props.loggedInAssociateId
          );
          
          this.props.actions.fetchMessages(
            interactionId,
            this.props.officeAssociateId,
            clientId,
            this.props.currentOfficeMembers,
            this.props.loggedInAssociateId
          );
        } else {
          //go back to Draft message
          this.props.actions.mainAction(GO_BACK_TO_DRAFT_MESSAGE, null);
        }
      }
    };

    const handleStarClick = (event) => {
      event.stopPropagation();
      if (
        event.key === "Enter" ||
        event.key === " " ||
        event.type === "click"
      ) {
        toggleStar();
      }
    };

    const toggleStar = () => {
      if (interactionId !== "tempInteractionId") {
        this.props.actions.patchInteractionStarredSetting(
          this.props.officeAssociateId,
          !starred,
          clientId,
          interactionId,
          this.props.loggedInAssociateId
        );
      }
    };
    const loadChatCard = () => {
      return (
        <>
          <div
            tabIndex="0"
            role="listitem"
            className={
              "sfconnect-chat-card" +
              (_isActive ? " active" : "") +
              (!isMessageViewed ? " unread" : "")
            }
            onClick={(event) => {
              handleChange(event, interactionId, clientId);
            }}
            onKeyPress={(event) => {
              keyPressed(event, interactionId, clientId);
            }}
            title={
              isMessageViewed
                ? "Message read from " + customerName
                : "New message from " + customerName
            }>
            <div className="-oneX-row info-area">
              <span className="clipped-text">
                {"message" + (isMessageViewed ? " read" : " unread")}
              </span>
              <div className="name-number">
                <h3 className="name">
                  {customerName}
                  {protectedPersonIndicator && (
                    <span className="-oneX-labels associate-initial-section protected-person-label">
                      Protected Person
                    </span>
                  )}
                </h3>

                {customerName !== phoneNumber && (
                  <p className="number">{phoneNumber}</p>
                )}
              </div>
              <div className="date-container">
                <p className="date">{messageDate}</p>
              </div>
            </div>
            {lastOutgoingSender && (
              <>
                <div className="-oneX-row">
                  <div className="last-reply-from-left">
                    <span aria-label="last associate replyed"><b>Last Reply From: </b></span>
                  </div>
                  {lastReplierType === "associate" && <div className="associate-initial-section">
                    <span aria-label="last associate replyed">SF</span>
                  </div> }
                  <div className="last-reply-from-right">{lastOutgoingSender}</div>
                </div>
                <br />
              </>
            )}
            <div className="-oneX-container chat-preview">
              <p className="-oneX-col-10">
                <MatchHighlight
                  fuzzySearchResults={fuzzySearchResults}
                  fuzzySearchKey="lastMessage.messageContent.text"
                  text={messagePreview}
                />
              </p>
              {!hidden.value && (
                <div className="-oneX-col-2 star-col">
                  <div className="-oneX-icon-container star-container -oneX-icon--interactive">
                    <button
                      onKeyDown={handleStarClick}
                      onClick={handleStarClick}
                      className="star-button"
                      aria-label={
                        starred
                          ? "Selected follow-up toggle"
                          : "Not selected follow-up toggle"
                      }
                      title={
                        starred
                          ? "Click to stop following this interaction"
                          : "Click to follow this interaction"
                      }>
                      {starred ? (
                        <span className="-sfc-following">&#9733;</span>
                      ) : (
                        <span
                          className="-sfc-follow"
                          style={{
                            visibility:
                              interactionId === "tempInteractionId"
                                ? "hidden"
                                : "visible",
                          }}>
                          &#9734;
                        </span>
                      )}
                      <span className="starred-label -oneX-icon-title">
                        {starred ? "Following" : ""}
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {!lastOutgoingSender && <br />}
            {virtualAssistantActive && (
              <>
                <br />
                <div className="-oneX-row virtual-assistant-section">
                  <span aria-label="agent name"> Active </span>
                  SF Virtual Assistant
                </div>
              </>
            )}
            {/* {!virtualAssistantActive && virtualAssistantFollowup && (
              <>
                <br />
                <div className="-oneX-row virtual-assistant-section">
                  <span aria-label="agent name" className="followup">
                    {" "}
                    Follow-up{" "}
                  </span>
                  SF Virtual Assistant
                </div>
              </>
            )} */}
            <div className="-oneX-container -sfc-onhover-container">
              <div className="-oneX-row">
                {interactionId !== "tempInteractionId" && (
                  <Assign
                      interactionId={interactionId}
                      agentId={this.props.officeAssociateId}
                      clientId={this.props.clientId}
                      loggedInAssociateId={this.props.loggedInAssociateId}
                      hidden={hidden}
                      assigned={assigned}
                    />
                )}
                {hidden.value && (
                  <Archive
                    currentInteractionId={currentInteractionId}
                    interactionId={interactionId}
                    clientData={_clientDataForArchive}
                    virtualAssistantActive={virtualAssistantActive}
                  />
                )}
                {!starred && (
                  <Hide
                    interactionId={interactionId}
                    virtualAssistantActive={virtualAssistantActive}
                    hidden={hidden}
                    agentId={this.props.officeAssociateId}
                    clientId={this.props.clientId}
                    loggedInAssociateId={this.props.loggedInAssociateId}
                  />
                )}
              </div>
            </div>
            <Modal
              key={interactionId}
              interactionId={interactionId}
              clientDataForArchive={_clientDataForArchive}
            />
          </div>
        </>
      );
    };
    //redundant variables passed in since they already are in scope
    const keyPressed = (event, interactionId) => {
      if (event.key === "Enter" || event.key === " ") {
        //Enter or Spacebar
        handleChange(event, interactionId, clientId);
      }
    };

    const _isActive =
      currentInteractionId && currentInteractionId === interactionId
        ? true
        : false;
    return <>{loadChatCard()}</>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        fetchMessages,
        patchInteractionStarredSetting,
        patchInteractionReadSetting,
        mainAction,
      },
      dispatch
    ),
  };
};

const mapStateToProps = (state) => ({
  token: state.authReducer.token,
  officeAssociateId: state.userInfoReducer.officeAssociateId,
  currentOfficeMembers: state.userInfoReducer.currentOfficeMembers,
  loggedInAssociateId: state.userInfoReducer.loggedInAssociateId,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatCard);

import React, { Component } from "react";
import { connect } from "react-redux";
import Notification from "../../components/Notification";
import { bindActionCreators } from "redux";
import { SET_MMS_INDEX } from "redux/actions/types";
import {
  getMmsUrl,
  markAsSPI,
  mainAction,
} from "redux/actions/interactionsActions";
import Modal from "components/CustomerSearch/Modal";
class Attachment extends Component {
  alignColumn() {
    return this.props.attachmentAlignment === "right"
      ? "-oneX-offset-5 -oneX-offset-sm-7 -oneX-offset-md-9 -oneX-no-gutters"
      : "-oneX-col-7 -oneX-col-sm-5 -oneX-col-md-3 -oneX-no-gutters";
  }

  renderAttachment() {
    const { attachment, message, agentId, attachmentIndex } = this.props;
    if (attachment.attachmentUrl) {
      return (
        <>
          <div className="-oneX-row display-attachment">
            <div className={this.alignColumn()}>
              <a
                href={attachment.attachmentUrl}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(attachment.attachmentUrl, "_blank");
                }}
                title="opens image in new window"
                rel="noopener noreferrer">
                <img
                  src={attachment.attachmentUrl}
                  className="-sfc-attachment"
                  alt="No preview available. (file may still open)"
                />
              </a>
            </div>
            <div className={this.alignColumn()}>
              <button
                className="-oneX-btn-secondary -oneX-btn-small"
                onClick={() => {
                  this.props.actions.mainAction(SET_MMS_INDEX, {
                    messageIndex: this.props.index,
                    attachmentIndex: attachmentIndex,
                    attachmentMessage: message,
                    attachmentUrl: attachment.attachmentUrl,
                  });
                  window.oneX.Modal.showModal(
                    this,
                    "oneX-modal-spi-confirmation-" + this.props.index
                  );
                }}>
                Mark SPI
              </button>
            </div>
            <Modal
              attachmentUrl={attachment.attachmentUrl}
              messageIndex={this.props.index}
            />
          </div>
        </>
      );
    } else if (attachment.spiIndicator) {
      return (
        <Notification type="informational" className="notification">
          The attachment has been blocked or removed
        </Notification>
      );
    } else {
      return (
        <div className="-oneX-row">
          <div className={this.alignColumn()}>
            <button
              id={
                "attachment_" + this.props.attachmentIndex + message.messageId
              }
              type="submit"
              className="-oneX-btn-secondary -oneX-btn-small"
              onClick={() => {
                this.props.actions.getMmsUrl(
                  message.messageId,
                  message.interactionId,
                  message.createdOn,
                  attachment.Key,
                  agentId,
                  this.props.index,
                  attachmentIndex
                );
              }}>
              View Attachment
            </button>
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div className="attachment" style={{ marginBottom: "5px" }}>
        {this.renderAttachment()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentInteraction:
      state.interactionsReducer.currentInteraction.interactionId,
    interactions: state.interactionsReducer,
    agentId: state.userInfoReducer.officeAssociateId,
    token: state.authReducer.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        markAsSPI: markAsSPI,
        getMmsUrl: getMmsUrl,
        mainAction: mainAction,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Attachment);

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { optInAgentOffice } from "../redux/actions/officeSettingsActions";
import { sfc_routes } from "../utils/general";
import Notification from "../components/Notification";
import { logUIErrors } from "redux/actions/interactionsActions";

const TITLE = "Sign up for SF Connect";
class Signup extends Component {
  constructor(props) {
    super(props);
    const phoneList = this.props.agentPhoneList.filter(
      (agent) => agent.associateID === this.props.officeAssociateId
    );
    const signingupOffice = this.props.agentsOfficeList.find((element) => {
      return element.id === this.props.officeAssociateId;
    });
    this.state = {
      officePhone: phoneList[0].displayedPhone,
      officeStateCode: signingupOffice.displayedStateAgentCode,
      officeAssociateName: signingupOffice.name,
      optedInFlag: false,
      checked: {
        read_and_agree: false,
        authorize_phone: false,
      },
    };
  }
  componentDidMount() {    
    this.props.actions.logUIErrors(
      this.props.officeAssociateId,
      JSON.stringify({
        officeSettingsReducer: JSON.stringify(this.props.officeSettingsReducer),
        loggedInAssociateId: this.props.loggedInAssociateId,
      }),
      "Signup Page load",
      false
    );
  }

  onClickHandler(event) {
    if (event.target.id === "read_and_agree") {
      this.setState({
        checked: {
          read_and_agree: event.target.checked,
          authorize_phone: this.state.checked.authorize_phone,
        },
      });
    } else if (event.target.id === "authorize_phone") {
      this.setState({
        checked: {
          read_and_agree: this.state.checked.read_and_agree,
          authorize_phone: event.target.checked,
        },
      });
    }
  }

  //MANIPULATE PHONE NUMBER FOR TESTING IN ENV1
  getTempPhoneForTesting(realPhone) {
    let tempPhoneForEnvTesting = "";
    //SOA: Alton Smith
    if (realPhone === "9127641995") {
      tempPhoneForEnvTesting = "7755427631";
    }
    //MOA: Kyle Angelle
    if (realPhone === "7134369185") {
      tempPhoneForEnvTesting = "9289626924";
    }
    //MOA: Tony Pope
    if (realPhone === "8438848541") {
      tempPhoneForEnvTesting = "2627886233";
    }
    //MOA: Tony Pope
    if (realPhone === "8438511611") {
      tempPhoneForEnvTesting = "4022078920";
    }
    //Jim Diaz
    if (realPhone === "3056778759") {
      tempPhoneForEnvTesting = "9525209795";
    }
    //Chad Gregorini
    if (realPhone === "4127846728") {
      tempPhoneForEnvTesting = "9525208441";
    }
    //Jim Martensen
    if (realPhone === "2106564267") {
      tempPhoneForEnvTesting = "7752978157";
    }
    //Mike Fisher
    if (realPhone === "8042646015") {
      tempPhoneForEnvTesting = "8589000927";
    }
    //Mike Fisher
    if (realPhone === "8042306015") {
      tempPhoneForEnvTesting = "8589009232";
    }
    //Andrew Case
    if (realPhone === "9726850044") {
      tempPhoneForEnvTesting = "8509191756";
    }
    //Adam Worrell
    if (realPhone === "8595869112") {
      tempPhoneForEnvTesting = "4302133228";
    }
    return tempPhoneForEnvTesting;
  }

  onSubmitHandler(event) {
    console.log("onSubmitHandler: ",event);
    event.preventDefault();
    //Uncomment below line in test
    //if(this.getTempPhoneForTesting(this.getPhone()).length === 10){
    //Comment below line (if condition line only) in test env
    let phoneNumber = this.getPhone();
    if (phoneNumber.length === 10) {
      const signingupOffice = this.props.agentsOfficeList.find((element) => {
        return element.id === this.props.officeAssociateId;
      });
      const agentName = signingupOffice.name;
      if(agentName.startsWith("SFO")){
        console.log("agentName: ",agentName);
        this.props.actions.optInAgentOffice(
          this.props.officeAssociateId,
          phoneNumber,
          this.props.loggedInAssociateId,
          true
        );
      }else{
        console.log("agentName: ",agentName);
        this.props.actions.optInAgentOffice(
          this.props.officeAssociateId,
          phoneNumber
        );
      }
    }
  }

  getPhone() {
    let phoneList = 0;
    if (this.props.agentPhoneList.length > 0) {
      phoneList = this.props.agentPhoneList.filter(
        (agent) => agent.associateID === this.props.officeAssociateId
      );
      if (phoneList.length > 0) {
        return phoneList[0].phone;
      }
    }else{
      return "";
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="-oneX-container signup-page">
          <div className="-oneX-row -sfc-mt-10">
            <div className="-oneX-col">
              <p className="-oneX-heading--h3 -sfc-mt-15">
                Welcome to SF Connect!
              </p>
            </div>
          </div>

          {this.props.isAgentEnabled ? (
            <>
              <div className="-oneX-row -sfc-mt-20">
                <div className="-oneX-col">
                  <Notification type="confirmation" className="notification">
                    <h4>
                      Your request has been submitted! Office activation will
                      take up to 30 minutes.
                    </h4>
                  </Notification>
                </div>
              </div>
              <div className="-oneX-row -sfc-mt-20">
                <img
                  className="-sfc"
                  src="./images/couple_messaging.jpg"
                  alt="couple sending text message"
                />
                {/* <div className="-sfc-logo"></div> */}
              </div>
              <br />
              <div className="-oneX-row -sfc-mt-20">
                <div className="-oneX-col ">
                  <button
                    className="-oneX-btn-primary refresh-button"
                    title="Refresh SFConnect app"
                    onClick={() => {
                      window.location.href = sfc_routes.pages.home;
                    }}>
                    After 30 minutes, click here to open SF Connect.
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="-oneX-row -sfc-mt-20">
                <div className="-oneX-heading--h5 -sfc-mt-20">
                  {(this.state.officeAssociateName).startsWith("SFO") && 
                    <h3>Your SFO office [{this.state.officeStateCode}] is not currently text enabled.</h3>
                  }
                  {!(this.state.officeAssociateName).startsWith("SFO") && 
                    <h3>Your office [{this.state.officeStateCode}] is not currently text enabled.</h3>
                  }
                  <p className="-oneX-heading--h5 -sfc-mt-20">
                    Please confirm the office phone number that you would like
                    to activate:&nbsp;&nbsp;
                    {this.state.officePhone}
                  </p>
                </div>
              </div>
              <div className="-oneX-row -sfc-mt-20">
                <div className="-oneX-col">
                  <Notification type="warning" className="notification">
                    SF Connect only uses the agent office phone number. Wireless
                    phone numbers are not eligible.<br></br>
                    If you opt in a wireless phone number, text messages
                    <strong>will not</strong>be delivered and/or received{" "}
                    <br></br>via the SF Connect application.<br></br>
                    Do not activate your office until the correct number is
                    displayed above.
                    <p className="-sfc-mt-10">
                      Call the ECC Support Center at 877-889-2294 to correct
                      your phone number.
                    </p>
                  </Notification>
                </div>
              </div>
              <div className="-oneX-row -sfc-mt-20">
                <div className="-oneX-col">
                  Currently, Shared agencies are accessed by the initial agent, who opted-in.
                  Shared ATMs, however, can access all shared books of business.
                </div>
              </div>
              <div className="-oneX-row -sfc-mt-20">
                <div className="-oneX-col">
                  <fieldset className="-oneX -oneX-fieldset">
                    <input
                      id="read_and_agree"
                      type="checkbox"
                      onClick={(e) => this.onClickHandler(e)}
                      className="-oneX-checkbox"
                    />
                    <label for="read_and_agree">
                      I have read and agree to the{" "}
                      <a
                        href="https://sfnet.opr.statefarm.org/agency/training/rollout/sfConnect/index.shtml"
                        target="_blank"
                        rel="noopener noreferrer">
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://intranet.opr.statefarm.org/entpolicies/gem_153_privacy_pers_info.pdf"
                        target="_blank"
                        rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                      .
                    </label>
                    <br />
                    <input
                      id="authorize_phone"
                      type="checkbox"
                      onClick={(e) => this.onClickHandler(e)}
                      className="-oneX-checkbox"
                    />
                    {(this.state.officeAssociateName).startsWith("SFO") && 
                      <label for="authorize_phone">I authorize State Farm to text enable my SFO office [{this.state.officeStateCode}] phone number displayed above.
                      </label>
                      }
                      {!(this.state.officeAssociateName).startsWith("SFO") && 
                        <label for="authorize_phone">I authorize State Farm to text enable my main office phone number displayed above.</label>
                      }
                  </fieldset>
                </div>
              </div>
              <br />
              <div className="-oneX-col-5">
                <button
                  type="Submit"
                  className="-oneX-btn-primary activate-button"
                  onClick={(e) => this.onSubmitHandler(e)}
                  disabled={
                    this.state.checked.read_and_agree &&
                    this.state.checked.authorize_phone &&
                    this.props.isAgent
                      ? ""
                      : "disabled"
                  }>
                  Activate My Office
                </button>
              </div>
              <br />
              {this.props.showProvisionError && (
                <Notification type="error">
                  Enablement has failed for this number. Please submit a service
                  ticket.
                </Notification>
              )}

              {!this.props.isAgent && (
                <Notification type="error">
                  Only agents can complete the opt-in process.
                </Notification>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.authReducer.token,
  officeAssociateId: state.userInfoReducer.officeAssociateId,
  agentPhoneList: state.userInfoReducer.agentOfficePhoneList,
  agentsOfficeList: state.userInfoReducer.agentsOfficeList,
  isAgent: state.userInfoReducer.isAgent,
  loggedInAssociateId: state.userInfoReducer.loggedInAssociateId,
  isAgentEnabled: state.officeSettingsReducer.isAgentEnabled,
  showProvisionError: state.officeSettingsReducer.showProvisionError,
  officeSettingsReducer: state.officeSettingsReducer,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        optInAgentOffice,        
        logUIErrors,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

import axios from "axios";
import {
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_ERROR,
  PATCH_UPDATE_CUSTOMER_PREFERENCES,
  GET_CUSTOMER_INFO,
  STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION,
} from "./types";
import { logUIErrors } from "./interactionsActions";

export const fetchCustomersByName = (agentId, lastName, exactMatch, firstName) => (dispatch) => {
  dispatch({ type: FETCH_CUSTOMERS_REQUEST });
  if (!exactMatch) {
    lastName = lastName + "*";
  }

  let queryParameters = `lastName=${lastName}`;
  if (firstName) {
    queryParameters += `&firstName=${firstName}*`;
  }

  axios
    .get(
      process.env.REACT_APP_AWS_API_URL +
      `agents/${agentId}/clients?${queryParameters}`
    )
    .then((response) =>
      dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: response.data })
    )
    .catch((error) => {
      dispatch({ type: FETCH_CUSTOMERS_ERROR, error });
      dispatch(logUIErrors(agentId, error, "Fetch Customers by Name", true));
    });
};

export const fetchCustomersByPhoneNumber = (agentId, phoneNumber) => (dispatch) => {
  dispatch({ type: FETCH_CUSTOMERS_REQUEST });

  axios
    .get(
      process.env.REACT_APP_AWS_API_URL + `agents/${agentId}/clients?phoneNumber=${phoneNumber}`)
    .then((response) =>
      dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: response.data })
    )
    .catch((error) => {
      dispatch({ type: FETCH_CUSTOMERS_ERROR, error });
      dispatch(logUIErrors(agentId, error, "Fetch Customers by Phone", true));
    });
};

export const clearCustomerRows = () => {
  return {
    type: "CLEAR_CUSTOMERS",
  };
};

export const patchUpdateCustomerPreferences = (agentId, clientId, phoneNumber, doNotTextValue) => (dispatch) => {
  axios
    .patch(
      process.env.REACT_APP_AWS_API_URL +
      `agents/${agentId}/clients/${clientId}/clientPreferences`,
      {
        clientPhone: phoneNumber,
        doNotText: doNotTextValue
      }
    )
    .then((response) =>
      dispatch({
        type: PATCH_UPDATE_CUSTOMER_PREFERENCES,
        payload: {
          clientID: clientId,
          donotText: doNotTextValue
        }
      })
    )
    .catch((error) => {
      dispatch(
        logUIErrors(agentId, error, "Patch Update Customer preferences", true)
      );
    });
};

export const archiveInteractionAndDeleteCache = (
  agentId,
  interactionId,
  clientId,
  loggedInAssociateId,  
  phoneNumber
) => async (dispatch) => {

  //Step1: Delete Client text preferences data from cache
  await axios
    .delete(
      process.env.REACT_APP_AWS_API_URL +
        `agents/${agentId}/clients/${clientId}/clientPreferences?phoneNumber=${phoneNumber}`
    )
    .then(async () => {
      //Step2: Archive old Interaction if exists
      if (interactionId !== "tempInteractionId") {
        await axios.delete(
          process.env.REACT_APP_AWS_API_URL +
            `interactions/${interactionId}/clients/${clientId}/?agentId=${agentId}&archivedByAssociateId=${loggedInAssociateId}&lob=Unknown&intent=Unknown`
        );
      }
      //Step3: Capture these informations in CW
      const logMessage =
            "Customer phone number updated in ECRM(Archive & Delete Cache) :" +
            `ClientId=${clientId};InteractionId=${interactionId};PhoneNumber=${phoneNumber};loggedInAssociateId=${loggedInAssociateId}`;

      await axios
      .post(
        process.env.REACT_APP_AWS_API_URL + "logs",
        {
          sfconnectVersion: process.env.REACT_APP_VERSION || "Unknown",
          agentId: agentId,
          message: logMessage,
          logType: "INFO",
          interaction: null,
        },
        {
          headers: {
            selectedAgentID: agentId,
            "Content-Type": "application/json",
          },
        }
      )
    })
    .catch((error) => {
      dispatch(logUIErrors(agentId,error,"Error in ArchiveInteraction And DeleteCache in ECRM flow",true));
    });
};

export const getCustomerInfo = (token, clientId, agentId, phoneNumber, isProspect) => (dispatch) => {
  axios
    .get(process.env.REACT_APP_AWS_API_URL + `agents/${agentId}/clients?phoneNumber=${phoneNumber}&clientId=${clientId}`)
    .then((response) => {
      let customerDetails = response.data.length ? response.data[0] : {};
      if (!isProspect && response.data.length) {
        dispatch({ type: GET_CUSTOMER_INFO, payload: customerDetails });
      } else {
        dispatch({
          type: STORE_CUSTOMER_INFO_FOR_PROSPECT_RESOLUTION,
          payload: customerDetails,
        });
      }
    })
    .catch((error) => {
      dispatch(logUIErrors(agentId, error, "Get Customer information", true));
    });
};

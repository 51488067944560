import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateAssociateId } from "redux/actions/userInfoActions";
import {
  CLEAR_CURRENT_INTERACTION,
  RESET_FETCHED_VALUES,
  CLEAR_GENERIC_ERROR,
  RESET_INTERACTIONS,
} from "redux/actions/types";
import { mainAction } from "../redux/actions/interactionsActions";

import SelectAgentOffice from "../components/SelectAgentOffice";
import { sfc_routes } from "../utils/general";

import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const TITLE = "SF Connect | Select Office";

const Moa = (props) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    // Reset isFetched and isFetchedButNoOffice values
    props.actions.mainAction(RESET_FETCHED_VALUES, false);
    // Clear the generic error
    if (props.error) {
      props.actions.mainAction(CLEAR_GENERIC_ERROR, false);
    }
  });

  const onClickContinue = (officeAssociateId) => {
    if (officeAssociateId) {
      props.actions.mainAction(CLEAR_CURRENT_INTERACTION, null);
      props.actions.updateAssociateId(officeAssociateId);
      let officeAssociateSelected = props.agentsOfficeList.find(
        (agent) => agent.id === officeAssociateId
      );
      props.actions.mainAction(RESET_INTERACTIONS, {officeAssociateId, officeAssociateSelected, type: "moa"});
    }
    setRedirect(true);
  };

  const AgentCodeDropdownOptions =
    props.agentsOfficeList.length > 0 &&
    props.agentsOfficeList.map((agent, i) => {
      return (
        <option key={i} value={agent.id}>
          {agent.name} : {agent.displayedStateAgentCode}
        </option>
      );
    });

  if (redirect) {
    return <Redirect to={sfc_routes.pages.home} />;
  } else {
    return (
      <>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="-oneX-container">
          <div className="-oneX-row">
            <div className="moa-page">
              <SelectAgentOffice
                userName={props.userInfoReducer.loggedInAssociateName}
                options={AgentCodeDropdownOptions}
                click={onClickContinue}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  token: state.authReducer.token,
  userInfoReducer: state.userInfoReducer,
  interactionsReducer: state.interactionsReducer,
  agentsOfficeList: state.userInfoReducer.agentsOfficeList,
  error: state.interactionsReducer.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateAssociateId,
        mainAction,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Moa);

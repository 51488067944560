import { GET_APP_SETTINGS } from "../actions/types";

const createInitialState = () => {
  return {};
};

export default function (state = createInitialState(), action) {
  switch (action.type) {
    case GET_APP_SETTINGS: {
      let globalAnnouncementEnabled = false;
      let uiAlert = {
        title: "",
        content: ""
      };
      if (
        action.payload &&
        action.payload.uiAlert &&
        action.payload.uiAlert.title &&
        action.payload.uiAlert.content
      ) {
        globalAnnouncementEnabled = true;
        uiAlert.title = action.payload.uiAlert.title;
        uiAlert.content = action.payload.uiAlert.content;
      }
      return {
        ...state,
        globalAnnouncementTitle: uiAlert.title,
        globalAnnouncementContent: uiAlert.content,
        globalAnnouncementEnabled: globalAnnouncementEnabled
      };
    }
    default:
      return state;
  }
}

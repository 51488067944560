import React, { Component } from "react";
import { fetchTemplates } from "../../redux/actions/customTemplatesActions";
import Emoji from "components/Message/emoji";
import WordTrackDrawer from "components/Message/WordTrackDrawer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  patchInteractionReadSetting,
  downloadDigitalCard
} from "../../redux/actions/interactionsActions";
import MediaQuery from "react-responsive";

class MsgAreaButtonBar extends Component {
  constructor(props) {
    super(props);
    this.markUnread = this.markUnread.bind(this);
    this.downloadDigitalCard = this.downloadDigitalCard.bind(this);
  }

  openDrawer = () => {
    // Fetch custom templates and store in redux store
    this.props.actions.fetchTemplates(this.props.officeAssociateId);
    window.oneX.Drawer.showDrawer("WordTrackDrawerBtn");
  };

  markUnread() {
    this.props.actions.patchInteractionReadSetting(
      this.props.officeAssociateId,
      this.props.currentInteraction.interactionId,
      this.props.currentInteraction.clientId,
      1,
      this.props.loggedInAssociateId
    );
  }

  downloadDigitalCard(event) {
    this.props.actions.downloadDigitalCard(this.props.officeAssociateId);
  }

  render() {
    return (
      <>
        {/* | Desktop View Port | */}
        <div className="msg-navbar">
          <div className="-oneX-row -oneX-no-gutters bottom-nav">
            <div className="-oneX-col">
              <button
                className={"-oneX-btn-small -oneX-btn-secondary dropbtn"}
                id="WordTrackDrawerBtn"
                data-onex-opensdrawer="WordTrackDrawer"
                onClick={this.openDrawer}
              >
                Text Templates
              </button>
            </div>

            <WordTrackDrawer
              wordTracks={
                this.props.corporateTextTemplates ||
                this.props.actions.fetchTemplates("corporate")
              }
            />
            <div className="-oneX-col">
              <button
                className="-oneX-btn-small -oneX-btn-secondary"
                data={"read-unread"}
                onClick={this.markUnread}
              >
                Mark Unread
              </button>
            </div>
            {/* | Desktop View Port | */}
            <MediaQuery minWidth={991}>
              <div className="-oneX-col">
                <button
                  id="DigitalContactCardBtn"
                  className="-oneX-btn-small -oneX-btn-secondary"
                  data={"digitalcard"}
                  onClick={this.downloadDigitalCard}
                >
                  Digital Card
                </button>
              </div>
            </MediaQuery>
            <div className="-oneX-col">
              <Emoji data={"emoji"} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authReducer.token,
    officeAssociateId: state.userInfoReducer.officeAssociateId,
    corporateTextTemplates: state.customTemplatesReducer.corporateTextTemplates,
    loggedInAssociateId: state.userInfoReducer.loggedInAssociateId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        patchInteractionReadSetting,
        downloadDigitalCard,
        fetchTemplates
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MsgAreaButtonBar);

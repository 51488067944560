import { GET_USER_SETTINGS_GLOBAL, GET_USER_SETTINGS_OFFICE, SAVE_USER_SETTINGS_OFFICE } from "./types";
import { getOfficeSettings, saveOfficeSettings } from '../helpers/userSettingsLocalStorage';


export const getUserOfficeSettings = (userAssociateId, agentAssociateId, phoneNumber) => (dispatch) => {
    dispatch({ type: GET_USER_SETTINGS_OFFICE, payload: getOfficeSettings(userAssociateId, agentAssociateId, phoneNumber) });
};

export const saveUserSettingsForOffice = (userAssociateId, agentAssociateId, phoneNumber, key, value) => (dispatch) => {
    dispatch({ type: SAVE_USER_SETTINGS_OFFICE, payload: saveOfficeSettings(userAssociateId, agentAssociateId, phoneNumber)});
};
import { SET_API_GATEWAY_WEBSOCKET } from "../redux/actions/types.js";
import {
  dispatchWebsocketData,
  logWebSocketData,
} from "../redux/actions/webSocketActions.js";

export const wsReadyState = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export const apiGatewayWebsocketHandler = {
  socket: null,
  connectionString: null,
  readyState: wsReadyState.CLOSED,
  agentAssociateId: null,
  loggedInAssociateId: null,
  token: null,
  timeout: 250,
  errorHasBeenLogged: false,
  connectionAttemptNumber: 0,
  connect: function (url, token, agentAssociateId, loggedInAssociateId) {
    this.connectionString = url || this.connectionString;
    this.token = token || this.token;
    this.agentAssociateId = agentAssociateId || this.agentAssociateId;
    this.loggedInAssociateId = loggedInAssociateId || this.loggedInAssociateId;
    if (
      !(
        this.socket &&
        (this.socket.readyState === wsReadyState.OPEN ||
          this.socket.readyState === wsReadyState.CONNECTING)
      )
    ) {
      this.connectionAttemptNumber++;
      this.createWebsocket(
        this.agentAssociateId,
        this.loggedInAssociateId,
        this.token
      );
    }
    this.socket.addEventListener("open", () => {
      this.timeout = 250;
      this.connectionAttemptNumber = 0;
      this.readyState = wsReadyState.OPEN;
      this.errorHasBeenLogged = false;
      this.dispatchEvent();
    });
    this.socket.addEventListener("close", () => {
      this.socket = null;
      this.readyState = wsReadyState.CLOSED;
      this.dispatchEvent();
      if (this.connectionAttemptNumber < 50) {
        setTimeout(() => {
          this.connect();
        }, Math.min(10000, this.timeout > 10000 ? 10000 : (this.timeout += this.timeout)));
      }
    });
    this.socket.addEventListener("error", () => {
      if (!this.errorHasBeenLogged) {
        this.errorHasBeenLogged = true;
        logWebSocketData(
          this.agentAssociateId,
          "Generic WebSocket error.",
          `Error occurred with WebSocket connection for user with associateId ${this.loggedInAssociateId}.`,
          "ERROR"
        );
      }
    });
    return this.socket;
  },
  createWebsocket: function (agentAssociateId, loggedInAssociateId, token) {
    const url = `${this.connectionString}?agentAssociateId=${agentAssociateId}&userAssociateId=${loggedInAssociateId}`;
    this.readyState = wsReadyState.CONNECTING;
    this.socket = new WebSocket(url, token);
  },
  resetToken: function (token) {
    this.token = token;
  },
  dispatchEvent: function () {
    setTimeout(() => {
      dispatchWebsocketData(SET_API_GATEWAY_WEBSOCKET, {
        socket: this.socket,
        readyState: this.readyState,
      });
    }, 2000);
  },
};

import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_ASSIGNMENTS_FILTER,
  UPDATE_UNASSIGNED_FILTER,
  INACTIVE_ASSIGNMENTS_FILTER,
  CLEAR_INTERACTIONS,
} from "redux/actions/types";
import { fetchInteractions } from "../../redux/actions/interactionsActions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AssignmentFilter() {
  const dispatch = useDispatch();

  let officeMembers = useSelector(
    (state) => state.userInfoReducer.currentOfficeMembers
  );

  officeMembers = officeMembers.filter((officeMember) => !officeMember.system);
  const selectedAssignments = useSelector(
    (state) => state.interactionsReducer.selectedAssignmentsFilter
  );
  const returnUnassigned = useSelector(
    (state) => state.interactionsReducer.returnUnassigned
  );
  const filterChanged = useSelector(
    (state) => state.interactionsReducer.isFilterChanged
  );
  const selectAllAssignments = useSelector(
    (state) => state.interactionsReducer.selectAllAssignments
  );
  const returnInactiveAssignments = useSelector(
    (state) => state.interactionsReducer.returnInactiveAssignments
  );

  const officeAssociateId = useSelector((state) => state.userInfoReducer.officeAssociateId );
  const officePhone = useSelector((state) => state.officeSettingsReducer.officePhone);
  const [assignments, setAssignments] = useState(selectedAssignments);
  const [retrieveUnassignedToggle, setRetrieveUnassignedToggle] = useState(returnUnassigned);
  const [selectAllToggle, setSelectAllToggle] = useState(selectAllAssignments);
  const [inactiveAssignmentsToggle, setInactiveAssignmentsToggle] = useState(returnInactiveAssignments);
  const [isFilterChanged, setIsFilterChanged] = useState(filterChanged);
  const isHiddenFilterOn = useSelector(
    (state) => state.interactionsReducer.isHiddenFilterOn
  );
  const isNewFilterOn = useSelector(
    (state) => state.interactionsReducer.isNewFilterOn
  );
  const isReadFilterOn = useSelector(
    (state) => state.interactionsReducer.isReadFilterOn
  );
  const isRepliedToFilterOn = useSelector(
    (state) => state.interactionsReducer.isRepliedToFilterOn
  );
  const isFollowUpFilterOn = useSelector(
    (state) => state.interactionsReducer.isFollowUpFilterOn
  );
  const loggedInAssociateId = useSelector(
    (state) => state.userInfoReducer.loggedInAssociateId
  );



  useEffect(() => {
    if (
      selectedAssignments !== assignments ||
      retrieveUnassignedToggle !== returnUnassigned ||
      selectAllToggle !== selectAllAssignments ||
      inactiveAssignmentsToggle !== returnInactiveAssignments
    ) {
      setAssignments(selectedAssignments);
      setRetrieveUnassignedToggle(returnUnassigned);
      setSelectAllToggle(selectAllAssignments);
      setInactiveAssignmentsToggle(returnInactiveAssignments);
      setIsFilterChanged(filterChanged);
      dispatch(
        fetchInteractions(officeAssociateId, officeMembers, undefined, {
          hidden: isHiddenFilterOn,
          unread: isNewFilterOn,
          read: isReadFilterOn,
          repliedTo: isRepliedToFilterOn,
          followUp: isFollowUpFilterOn,
          assignments: selectedAssignments,
          returnUnassigned: returnUnassigned,
          returnInactiveAssignments: returnInactiveAssignments,
          isFilterChanged: filterChanged,
        })
      );
    }
  }, [
    selectedAssignments,
    assignments,
    dispatch,
    officeAssociateId,
    officeMembers,
    isHiddenFilterOn,
    isNewFilterOn,
    isReadFilterOn,
    isRepliedToFilterOn,
    isFollowUpFilterOn,
    returnUnassigned,
    retrieveUnassignedToggle,
    selectAllAssignments,
    selectAllToggle,
    returnInactiveAssignments,
    inactiveAssignmentsToggle,
    isFilterChanged,
  ]);

  const handleChange = (event) => {
    let selectedId = event.target.value.at(-1);

    if (selectedId === "unassigned") {
      //setIsFilterChanged(true);
      dispatch({
        type: UPDATE_UNASSIGNED_FILTER,
        payload: {
          officeMembers,
          isFilterChanged: true,
          officePhone: officePhone,
          agentAssociateId: officeAssociateId
        },
      });
    } else if (selectedId === "selectAll") {
      //setIsFilterChanged(true);
      dispatch({
        type: SET_ASSIGNMENTS_FILTER,
        payload: {
          selectedAssociateId: selectedId,
          selectAllChange: true,
          officeMembers,
          isFilterChanged: true,
          officePhone: officePhone,
          agentAssociateId: officeAssociateId
        },
      });
    } else if (selectedId === "inactiveAssignments") {
      //setIsFilterChanged(true);
      dispatch({
        type: INACTIVE_ASSIGNMENTS_FILTER,
        payload: {
          officeMembers,
          isFilterChanged: true,
          officePhone: officePhone,
          agentAssociateId: officeAssociateId
        },
      });
    } else {
      let selectedOfficeMember = officeMembers.find(
        (officeMember) => officeMember.associateId === selectedId
      );
      //setIsFilterChanged(true);
      dispatch({
        type: SET_ASSIGNMENTS_FILTER,
        payload: {
          selectedAssociateId: selectedId,
          selectedAssociateName: selectedOfficeMember.associateName,
          selectAllChange: false,
          officeMembers,
          isFilterChanged: true,
          officePhone: officePhone,
          agentAssociateId: officeAssociateId
        },
      });
    }

    dispatch({
      type: CLEAR_INTERACTIONS,
    });
  };

  const assignedNames = selectedAssignments.map((assignment) =>
    (assignment.associateId === loggedInAssociateId)
      ? "Me"
      : assignment.associateName
  );

  if (retrieveUnassignedToggle) {
    assignedNames.push("Unassigned");
  }
  
  if (inactiveAssignmentsToggle) {
    assignedNames.push("Unknown Team Member");
  }

  return (
    <>
      <FormControl sx={{ m: 1, ml: 2, width: 200 }}>
        <InputLabel id="assignedTo-checkbox-label">Filter by Assignments</InputLabel>
        <Select
          labelId="assignedTo-multiple-checkbox-label"
          id="assignedTo-multiple-checkbox"
          multiple
          value={assignedNames}
          onChange={handleChange}
          input={<OutlinedInput label="Filter by Assignments" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}>
          <MenuItem key="selectAll" value="selectAll">
            <Checkbox checked={selectAllToggle} />
            <ListItemText primary="Select All" />
          </MenuItem>
          <MenuItem key="unassigned" value="unassigned">
            <Checkbox checked={returnUnassigned} />
            <ListItemText primary="Unassigned" />
          </MenuItem>
          <MenuItem key="inactiveAssignments" value="inactiveAssignments">
            <Checkbox checked={returnInactiveAssignments} />
            <ListItemText primary="Unknown Team Member" />
          </MenuItem>
          {officeMembers.map((officeMember) => (
            <MenuItem
              key={officeMember.associateId}
              value={officeMember.associateId}>
              <Checkbox
                id={officeMember.associateId}
                checked={selectedAssignments.some(
                  (selectedAssociate) =>
                    selectedAssociate.associateId === officeMember.associateId
                )}
              />
              <ListItemText primary={officeMember.associateName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

import React, { Component } from 'react';
//import { Route } from 'react-router-dom'

export default class SelectAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OfficeAssociateId: '',
            error: {
                hasError: false,
                class: '',
                message: 'State Agent Code is required.',
                level: 'informational'
            }
        }
    }
    
    componentDidMount() {
        if (window.oneX) {
            window.oneX.addElement(this.refs.moaDropDown)
        }
    }

    onSubmitHandler = (event) => {
        const ele = this.refs.AgentCode
        if (ele.value === '') {
            this.setState({
                error: {
                    hasError: true,
                    class: '-oneX-widget--err',
                    message: this.state.error.message
                }
            })
            event.preventDefault();
        } else {
            this.setState({
                error: {
                    hasError: false,
                    class: '',
                    message: this.state.error.message
                }
            })
            this.props.click(ele.value) //This calls back to the MOA page to handle the click.
        }
    }

    onDropdownSwitch = (event) => {
        this.setState({
            OfficeAssociateId: this.refs.AgentCode.value,
            error: {
                hasError: false,
                class: '',
                message: this.state.error.message
            }})
    }

    render() {
        const { userName, options } = this.props;
        return (
            <>
                <div>
                    <div className="-oneX-heading--h3">
                        Welcome {userName}
                    </div>
                    <div className="moa-padding">
                        <div ref={'moaDropDown'} id="emptyDropdownWidgetError">
                            <label htmlFor="popDropdownAgentCode" className="-oneX-dropdown__floatingLabel">Choose Agent Code</label>
                            <select ref={'AgentCode'} id="popDropdownAgentCode" type="text" className="-oneX-dropdown" defaultValue="" onClick={this.onDropdownSwitch}>
                                <option value=""></option>
                                {options}
                            </select>
                            { this.state.error.hasError ?
                            <div id="emptyDropdownError" className="-oneX-widget__err-text" aria-live="assertive">
                                {this.state.error.message}
                            </div>
                            : null }
                        </div>
                    </div>
                    <div className=" moa-padding">
                        <button type="submit" className="-oneX-btn-primary" onClick={this.onSubmitHandler}>Continue</button>
                    </div>
                </div>
            </>
        )
    }
}
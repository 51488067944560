import axios from "axios";
import {
  GET_ASSOCIATE_INFORMATION,
  GET_ASSOCIATE_INFORMATION_ERROR,  
  SET_LOGGED_IN_USER,
} from "./types";

export const getAssociateInformation = (alias) => async (dispatch) => {
  axios
  .get(process.env.REACT_APP_AWS_API_URL + `user?alias=${alias}`)
    .then((response) => {
      dispatch({ type: GET_ASSOCIATE_INFORMATION, payload: response.data });
      dispatch({ type: SET_LOGGED_IN_USER, payload: response.data });
    })
    .catch((error) =>
      dispatch({ type: GET_ASSOCIATE_INFORMATION_ERROR, error })
    );
};

export const updateAssociateId = (officeAssociateId) => {
  return {
    type: "UPDATE_ASSOCIATE_ID",
    officeAssociateId,
  };
};
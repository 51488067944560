import {
    GET_USER_SETTINGS_OFFICE,
    SAVE_USER_SETTINGS_OFFICE
  } from "../actions/types";
  
  const createInitialState = () => {
    return {
        isFetched: false,
        office: undefined
    }
  };

  export default function (state = createInitialState(), action) {
    switch (action.type) {
      case GET_USER_SETTINGS_OFFICE: {
        return {
          isFetched: true,
          office: action.payload.office
        };
      }
  
      case SAVE_USER_SETTINGS_OFFICE: {
        return {
            ...state,
            userOfficeSettings: action.payload
          };
      }
  
      default:
        return state;
    }
  }
  
import store from "../";
import { logUIErrors } from "./interactionsActions.js";

export const dispatchWebsocketData = (type, payload) => {
  store.dispatch({ type: type, payload: payload });
};

export const logWebSocketData = (
  agentAssociateId,
  error,
  actionInError,
  logLevel
) => {
  store.dispatch(
    logUIErrors(agentAssociateId, error, actionInError, null, logLevel)
  );
};
